import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import './plugins/element.js'
import store from './store'
// 导入全局样式表
import './assets/css/global.css'

import topSearch from '@/components/TopSearch'
// import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import './init.js'
// import axios from 'axios'

import * as API from '@/api'

// 配置请求的根路径
// axios.defaults.baseURL = 'http://120.26.50.38:8080/plib'

// 在 request 拦截器中，展示进度条 NProgress.start()
// axios.interceptors.request.use(config => {
//   NProgress.start()
//   config.headers.Authorization = window.sessionStorage.getItem('token')
//   return config
// })
// 在 response 拦截器中，隐藏进度条 NProgress.done()
// axios.interceptors.response.use(config => {
//   NProgress.done()
//   return config
// })

router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  // document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})

Vue.use(ElementUI)
// Vue.prototype.$http = axios
Vue.component(topSearch.name, topSearch)

Vue.config.productionTip = false

new Vue({
  render: (h) => h(App),
  // 全局事件总线$bus配置
  beforeCreate () {
    Vue.prototype.$API = API
  },
  // 需要把router进行注册
  // 可以让全部的组件（非路由|路由组件）都可以获取到$route|$router属性
  // $route(路由)：可以获取到路由信息（path、query、params）
  // $router:进行编程式导航路由跳转push||replace
  router,
  // 在入口文件这里注册store,在每一个组件的身上都拥有一个$store这个属性
  store
}).$mount('#app')

Vue.prototype.checkImage = function (url) {
  return new Promise((resolve) => {
    const img = new Image()
    img.onload = function () {
      resolve(true) // 图片加载成功
    }
    img.onerror = function () {
      resolve(false) // 图片加载失败
    }
    img.src = url
  })
}

// 在 Vue 实例的原型上定义全局函数
Vue.prototype.getImg = function (book) {
  const coverUrl = book.StrCoverURL
  const ISBN = book.StrISBN
  const imgsrc = 'https://sanyue-oss-public.oss-cn-hangzhou.aliyuncs.com/image/bookcover/' + ISBN + '.jpg'
  if (coverUrl !== '') {
    this.checkImage(coverUrl)
      .then((result) => {
        if (result) {
          // 图片路径可访问
          book.StrCoverURL = coverUrl
        } else {
          // 图片路径无法访问，添加默认图片URL
          book.StrCoverURL = require('./assets/img/defaultBook.png')
        }
      })
      .catch(() => {
        // 图片路径检查出错，继续检查能否通过ISBN获取图片
        this.checkImageISBN(book, imgsrc)
      })
  } else {
    this.checkImageISBN(book, imgsrc)
  }
}

Vue.prototype.checkImageISBN = function (book, imgsrc) {
  this.checkImage(imgsrc)
    .then((result) => {
      if (result) {
        // 图片路径可访问
        book.StrCoverURL = imgsrc
      } else {
        // 图片路径无法访问，添加默认图片URL
        book.StrCoverURL = require('./assets/img/defaultBook.png')
      }
    })
    .catch(() => {
      // 图片路径检查出错，添加默认图片URL
      book.StrCoverURL = require('./assets/img/defaultBook.png')
    })
}
