<template>
    <div class="detailInfo">
      <topSearch />
      <div class="content">
        <el-card>
          <div class="selectBox">
            <!-- <div class="selectTime">
              时间范围
              <el-select v-model="searchForm.ChooseTime" placeholder="全部" class="selectItemBox" @change="getHotBooks">
                <el-option
                  v-for="option in selectTimes"
                  :key="option.index"
                  :label="option.optionName"
                  :value="option.index">
                </el-option>
              </el-select>
            </div> -->
<!--            <div class="selectLib">-->
<!--              分馆-->
<!--              <el-select v-model="searchForm.StrOrganizationID" placeholder="请选择分馆" class="selectItemBox" @change="getHotBooks" clearable>-->
<!--                <el-option-->
<!--                  v-for="option in selectLibs"-->
<!--                  :key="option.StrID"-->
<!--                  :label="option.StrName"-->
<!--                  :value="option.StrID">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </div>-->
            <div class="selectClass">
              分类
              <el-select v-model="searchForm.CLCCheck" placeholder="请选择" class="selectItemBox" @change="getHotBooks" clearable>
                <el-option
                  v-for="option in selectClasses"
                  :key="option.StrBookTypeName"
                  :label="option.StrBookTypeName + ' ' + option.Name"
                  :value="option.StrBookTypeName">
                </el-option>
              </el-select>
            </div>
          </div>
        </el-card>
        <el-card style="margin-top:10px;" v-loading="loading" element-loading-text="图书加载中...">
          <div class="showOnLibBook">
            <el-checkbox v-model="searchForm.OnLib" @change="getHotBooks">只显示在馆图书</el-checkbox>
          </div>
          <hr class="hr"/>
          <div class="bookListBox" v-for="(book, index) in this.bookList" :key="index">
              <div class="bookBox" @click="getBookInfo(index)">
                <div class="bookImgBox" >
                  <img :src="book.StrCoverURL" alt="" style="width:104px;height: 144px;object-fit: contain;object-position: center;">
                </div>
                <div class="bookInfoBox">
                  <div class="bookTitle">{{book.StrPositiveTitle}}</div>
                  <div class="bookInfo">
                    <div class="bookInfoLeft">
                      <div>责任者：{{book.StrFirstResponsible}}</div>
                      <div>出版社：{{book.StrPublisher}}</div>
                      <div>ISBN：{{book.StrISBN}}</div>
                      <div>借阅次数：{{book.IntBorrowedCount}}</div>
                    </div>
                    <div class="bookInfoRight">
                      <div>出版时间：{{book.StrPublicationDate}}</div>
                      <div>关键词：{{book.StrKeywords}}</div>
                      <div>分类号：{{book.StrTypeName}}</div>
                      <div>种次号：{{book.StrCallNumber}}</div>
                    </div>
                  </div>
                  <div class="bookPreview">
                    <div class="libCollection">馆藏({{book.IntNumberCopies}})</div>
                  </div>
                </div>
              </div>
            </div>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="searchForm.Page" :page-sizes="[10, 20, 30, 50]" hide-on-single-page
                           :page-size="searchForm.PerPage" layout="sizes, prev, pager, next, jumper" :total="totalBookNum">
            </el-pagination>
        </el-card>
      </div>
    </div>
</template>

<script>
import { getHotBooks, getAllLibs, getClcType } from '@/api'
export default {
  data () {
    return {
      loading: false,
      imgUrl: 'https://next.sanyue100.com/covers/',
      selectLibs: [],
      selectClasses: [
      //   {
      //   index: '',
      //   optionName: ''
      // }
      ],
      selectTimes: [{
        index: 0,
        optionName: '全部'
      },
      {
        index: -7,
        optionName: '最近一周'
      },
      {
        index: -30,
        optionName: '最近一月'
      },
      {
        index: -90,
        optionName: '最近三月'
      },
      {
        index: -180,
        optionName: '最近半年'
      },
      {
        index: -365,
        optionName: '最近一年'
      }],
      searchForm: {
        OnLib: false,
        Page: 1,
        PerPage: 10,
        CLCCheck: '',
        ChooseTime: 0
      },
      bookList: [],
      totalBookNum: 0
    }
  },
  created () {
    this.searchForm.StrOrganizationID = localStorage.getItem('library') == null ? '' : localStorage.getItem('library')
    // this.getAllLibs()
    this.getHotBooks()
    this.getClc()
  },
  methods: {
    async getAllLibs () {
      const result = await getAllLibs()
      if (result.ok) {
        this.selectLibs = result.msg
        // console.log(this.libraryAddresses)
      } else {
        return this.$message(result.msg)
      }
    },
    async getClc () {
      const result = await getClcType()
      if (result.ok) {
        this.selectClasses = result.msg
        // console.log(this.libraryAddresses)
      } else {
        return this.$message(result.msg)
      }
    },
    async getHotBooks () {
      this.loading = true
      this.searchForm.PerPage = 10
      this.searchForm.Page = 1
      const result = await getHotBooks(this.searchForm)
      if (result.ok) {
        this.bookList = result.msg
        // this.selectClasses = result.ClC
        this.totalBookNum = result.total
        for (var i in this.bookList) {
          this.getImg(this.bookList[i])
        }
        // console.log(this.libraryAddresses)
      } else {
        return this.$message(result.msg)
      }
      this.loading = false
    },
    // 每页显示条数更改
    handleSizeChange (newSize) {
      this.searchForm.PerPage = newSize
      this.getHotBooksPageChange()
    },
    // 监听页码值改变的事件
    handleCurrentChange (newPage) {
      this.searchForm.Page = newPage
      this.getHotBooksPageChange()
    },
    async getHotBooksPageChange () {
      this.loading = true
      const result = await getHotBooks(this.searchForm)
      if (result.ok) {
        this.bookList = result.msg
        // this.selectClasses = result.ClC
        this.totalBookNum = result.total
        for (var i in this.bookList) {
          this.getImg(this.bookList[i])
        }
        // console.log(this.libraryAddresses)
      } else {
        return this.$message(result.msg)
      }
      this.loading = false
    },

    getBookInfo (index) {
      var book = this.bookList[index]
      this.$router.push({
        path: '/bookInfo',
        query: { res: JSON.stringify(this.searchForm), book: JSON.stringify(book), router: 'hotBook' }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content{
  width: 1092px;
  margin: 20px auto 0 auto;
}
.selectBox{
  display: flex;
  align-items: center;
}
.selectItemBox {
  margin-left: 10px;
  width: 200px;
}
.selectLib, .selectClass{
  margin-left: 20px;
}
.hr{
  background-color: #e5e5e5;
  height:1px;
  border:none;
  margin: 16px 0 30px 0;
}
.bookListBox {
  margin-bottom: 14px;
}
.bookBox {
  padding: 30px;
  height: 170px;
  display: flex;
  border-bottom: #e5e5e5 1px solid;
  cursor: pointer;
  .bookImgBox {
    flex: 1;
    height: 140px;
  }
  .bookInfoBox {
    flex: 4;
  }
  .bookTitle{
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 1.25;
  }
}
.bookPreview {
  display: flex;
}
.bookInfo {
  color: #61666d;
  font-size: 14px;
  margin-bottom: 18px;
  display: flex;
  div{
    margin-bottom: 2px;
  }
  .bookInfoRight{
    flex: 5;
  }
  .bookInfoLeft {
    flex: 4;
  }
}
.libCollection {
  color: #409eff;
  font-size: 14px;
}
</style>
