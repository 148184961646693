<template>
  <el-container>
    <el-header>
      <div id="menudiv">
        <el-menu ref="head" router :default-active="$router.path" class="el-menu-demo" mode="horizontal" text-color="#fff" background-color= "#378ee8"  active-text-color="#fff" style="margin-left: 50px ">
          <el-menu-item index="/index">检索首页</el-menu-item>
          <!-- <el-menu-item index="/browse">分类浏览</el-menu-item> -->
          <el-menu-item index="/newBook">新书速递</el-menu-item>
          <el-menu-item index="/hotBook">热门图书</el-menu-item>
        </el-menu>
      </div>
      <div id="settingdiv">
        <el-button v-if="settingIsIcon" type="text" icon="el-icon-s-tools" circle style="margin-top: 3px" @click="setting()"></el-button>
        <el-button v-else type="text" style="margin-top: 8px;font-size: 18px" @click="setting()">{{libName}}</el-button>
      </div>
    </el-header>
    <el-main>
      <!-- <el-card class="contentCard"> -->
        <router-view></router-view>
      <!-- </el-card> -->
    </el-main>
    <el-dialog
      title="设置"
      :show-close="this.showClose"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <span style="margin-left: 10px">组织</span>
      <el-select v-model="organizationID" placeholder="请选择组织" clearable style="margin-left: 10px;">
        <el-option
          v-for="address in libraryAddresses"
          :key="address.StrID"
          :label="address.StrName"
          :value="address.StrID">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureChange()">确 定</el-button>
      </span>
    </el-dialog>
    <el-footer style="padding-top: 30px;">©2009-2021 杭州三阅信息技术有限公司</el-footer>
</el-container>
</template>

<script>
import { getAllLibs } from '@/api'
import {result} from "lodash";
import * as url from "url";

function receiveMessage (event) {
  try {
    var lib = JSON.parse(event.data)
    console.log(lib)
    localStorage.setItem('library', lib.libId)
    console.log(localStorage.getItem('library'))
    location.reload()
  } catch (error) {
    console.error('Error parsing JSON data:', error)
    // alert('数据获取失败，请手动登录！')
  }
}
window.addEventListener('load', function () {
  window.addEventListener('message', receiveMessage, false)
})
export default {
  data () {
    return {
      activePath: '',
      dialogVisible: false,
      libraryAddresses: [],
      organizationID: '',
      showClose: false,
      settingIsIcon: true,
      libName: '',
      isUrlProcessed: false
    }
  },
  created () {
    console.log(localStorage.getItem('library'))
    this.searchLibraryAddresses()
  },
  mounted () {
  },
  methods: {
    sureChange () {
      console.log(localStorage.getItem('library'))
      localStorage.setItem('library', this.organizationID)
      this.searchLibraryAddresses()
      this.dialogVisible = false
      location.reload()
    },
    async searchLibraryAddresses () {
      // const { data: res } = await this.$http.get('search/list')
      // if (res.meta.status !== 200) {
      //   return this.$message.console.error(res.meta.msg)
      // }
      // console.log(res.data)
      // this.libraryAddresses = res.data
      const result = await getAllLibs()
      if (result.ok) {
        this.libraryAddresses = result.msg
        this.changeLib()
      } else {
        return this.$message(result.msg)
      }
    },
    changeLib () {
      // eslint-disable-next-line no-unused-vars
      this.organizationID = localStorage.getItem('library') == null ? '' : localStorage.getItem('library')
      if (this.organizationID != null && this.organizationID !== '') {
        for (var i in this.libraryAddresses) {
          // eslint-disable-next-line eqeqeq
          if (this.libraryAddresses[i].StrID == this.organizationID) {
            this.libName = this.libraryAddresses[i].StrName
            this.settingIsIcon = false
          }
        }
      } else {
        this.libName = ''
        this.settingIsIcon = true
      }
    },
    // 判断是否为移动端
    isMobile () {
      return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    },
    setting () {
      this.dialogVisible = true
    },
    handleClose (done) {
      var Url = new URLSearchParams(window.location.search)
      Url.delete('lib')
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    }
  }
}
</script>

<style lang="less" scoped>
  .el-menu-item{
    border-bottom: none !important;
    font-size: 15px !important;
  }
  .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
      background-color: rgba(0, 0, 0,0.2) !important;
  }
  .el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: none !important;
  }
  #menudiv{
    display: inline-block;
  }
  #settingdiv{
    display: inline-block;
    float: right;
  }
  /deep/ #menudiv>.el-menu.el-menu--horizontal {
    border-bottom: none !important;
  }
  #settingdiv>.el-button--text{
    color: #ffffff;
  }
  #settingdiv>.el-button{
    font-size: 28px ;
  }
</style>
