<template>
  <div id="maxBox">
    <div class="detailInfo">
      <el-card>
        <div class="backTop">
          <el-button @click="backToBookList">返回</el-button>
        </div>
        <div class="bookInfo">
          <div class="bookImg"><img :src="book.StrCoverURL" alt="" id="myImage"></div>
          <div class="info">
            <div class="bookTitle">{{ book.StrPositiveTitle }}</div>
            <div class="infoTable">
              <div class="infoTableItem" v-if="book.StrISBN != ''">
                <div class="infoTableItemTitle">ISBN：</div>
                <div class="infoTableItemText">{{ book.StrISBN }}</div>
              </div>
              <div class="infoTableItem" v-if="book.StrFirstResponsible != ''">
                <div class="infoTableItemTitle">作者：</div>
                <div class="infoTableItemText">{{ book.StrFirstResponsible }}</div>
              </div>
              <div class="infoTableItem" v-if="book.StrPublisher != ''">
                <div class="infoTableItemTitle">出版社：</div>
                <div class="infoTableItemText">{{ book.StrPublisher }}</div>
              </div>
              <div class="infoTableItem" v-if="book.StrPublicationDate != ''">
                <div class="infoTableItemTitle">出版时间：</div>
                <div class="infoTableItemText">{{ book.StrPublicationDate }}</div>
              </div>
              <div class="infoTableItem" v-if="book.FloatPricePer != 0.0">
                <div class="infoTableItemTitle">定价：</div>
                <div class="infoTableItemText">{{ book.FloatPricePer }}元</div>
              </div>
              <div class="infoTableItem" v-if="book.StrNumberPages != 0">
                <div class="infoTableItemTitle">页数：</div>
                <div class="infoTableItemText">{{ book.StrNumberPages }}页</div>
              </div>
              <div class="infoTableItem" v-if="book.StrTypeName != ''">
                <div class="infoTableItemTitle">分类号：</div>
                <div class="infoTableItemText">{{ book.StrTypeName }}</div>
              </div>
              <div class="infoTableItem" v-if="book.StrCallNumber != ''">
                <div class="infoTableItemTitle">种次号：</div>
                <div class="infoTableItemText">{{ book.StrCallNumber }}</div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <div class="contentValidity" v-if="book.StrSummary != ''">
      <el-card>
        <div class="main_title">内容简介</div>
        <p style="text-indent:2em;">{{ book.StrSummary }}</p>
      </el-card>
    </div>
    <div class="holdingInfo">
      <el-card>
        <div class="main_title">馆藏信息</div>
        <div class=""></div>
        <div class="holdingInfoTable">
          <el-table
            v-loading="loading0" element-loading-text="图书加载中..."
            :data="holdingInfoList"
            style="width: 100%">
            <el-table-column
              v-if="holdingInfoTableShowStrCallNumber"
              prop="StrCallNumber"
              label="种次号">
            </el-table-column>
            <el-table-column
              v-else
              prop="StrTypeName"
              label="索书号">
            </el-table-column>
            <el-table-column
              prop="StrBookBarcode"
              label="条形码">
            </el-table-column>
            <el-table-column
              prop="StrOrganizationName"
              label="所在馆">
            </el-table-column>
            <el-table-column label="馆藏状态">
              <template slot-scope="scope">
                <span>{{ scope.row.IsBorrowed ? '借出' : '在馆' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="IntBorrowedCount"
              label="借阅次数">
            </el-table-column>
            <el-table-column prop="StrBookshelfName" label="书架">
              <template slot-scope="scope">
                <span>{{ scope.row.StrBookshelfName == '' ? '暂无信息' : scope.row.StrBookshelfName }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="StrBookshelfName" label="层架标">
              <template slot-scope="scope">
              <span>{{scope.row.StrBookshelfName=='' ? '暂无信息' : scope.row.StrBookshelfName}}</span>
              </template>
            </el-table-column> -->
          </el-table>
        </div>
      </el-card>
    </div>

    <!-- 书评 -->
    <!-- <div class="bookReview">
      <el-card>
        <div class="main_title">豆瓣书评</div>
        </el-card>
    </div> -->

    <!-- 网络书店 -->
    <!--      <div class="bookStore">-->
    <!--        <el-card>-->
    <!--          <div class="main_title">网络书店</div>-->
    <!--          <div>-->
    <!--            <div class="bookBuyUrl">-->
    <!--              <a :href="dangdangUrl" target="_blank" >在当当中查看</a>-->
    <!--            </div>-->
    <!--            <div class="bookBuyUrl">-->
    <!--              <a :href="doubanUrl" target="_blank">在豆瓣中查看</a>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          </el-card>-->
    <!--      </div>-->
    <div class="authorOtherBooks">
      <el-card>
        <div class="main_title">作者其他图书</div>
        <div>
          <el-table v-loading="loading" element-loading-text="图书加载中..."
                    :data="otherBooks"
                    style="width: 100%">
            <el-table-column
              prop="StrPositiveTitle"
              label="书名">
            </el-table-column>
            <el-table-column
              prop="StrFirstResponsible"
              label="作者">
            </el-table-column>
            <el-table-column prop="StrPublisher" label="出版社">
              <template slot-scope="scope">
                  <span>
                    {{ scope.row.StrPublisher == '' ? '暂无信息' : scope.row.StrPublisher }}
                  </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="StrPublicationDate"
              label="出版日期">
            </el-table-column>
            <el-table-column
              v-if="authorOtherBooksTableShowStrCallNumber"
              prop="StrCallNumber"
              label="种次号">
            </el-table-column>
            <el-table-column
              v-else
              prop="StrTypeName"
              label="索书号">
            </el-table-column>
            <el-table-column prop="StrBookshelfName" label="层架标">
              <template slot-scope="scope">
                  <span>
                    {{ scope.row.StrBookshelfName == '' ? '暂无信息' : scope.row.StrBookshelfName }}
                  </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--          手机端-->
        <div v-if="UseMobile" style="max-width: 100%;height: 35px;overflow: auto">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" small hide-on-single-page
                         :current-page="searchOtherBooksForm.Page" :page-size="searchOtherBooksForm.PerPage"
                         layout="pager,jumper" :total="totalBookNum" :pager-count="5">
          </el-pagination>
        </div>
        <!--          电脑端-->
        <el-pagination v-else @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="searchOtherBooksForm.Page" :page-sizes="[10, 20, 30, 50]"
                       :page-size="searchOtherBooksForm.PerPage"
                       hide-on-single-page layout="sizes, prev, pager, next, jumper" :total="totalBookNum">
        </el-pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
import { getBooksByConditions, getAuthorOtherBooks } from '@/api'

export default {
  name: 'detailInfo',
  data () {
    return {
      holdingInfoTableShowStrCallNumber: true,
      authorOtherBooksTableShowStrCallNumber: true,

      loading: false,
      loading0: false,
      totalBookNum: 0,
      UseMobile: false,
      dangdangUrl: '',
      doubanUrl: '',
      imgUrl: 'https://next.sanyue100.com/covers/',
      holdingInfoList: [],
      searchForm: {},
      searchBooksForm: {},
      book: {},
      otherBooks: [],
      searchOtherBooksForm: {
        Page: 1,
        PerPage: 999
      },
      routerPath: ''
    }
  },
  created () {
    this.UseMobile = document.body.clientWidth < 620

    this.searchForm = Object.assign({}, JSON.parse(this.$route.query.res))
    this.book = Object.assign({}, JSON.parse(this.$route.query.book))
    this.getImg(this.book)
    this.routerPath = this.$route.query.router
    this.getBooksByISBN()
    this.dangdangUrl = 'http://search.dangdang.com/?key=' + this.book.StrISBN + '\\&act=input'
    this.doubanUrl = 'https://search.douban.com/book/subject_search?search_text=' + this.book.StrISBN
    if (this.book.StrFirstResponsible !== 'NULL' && this.book.StrFirstResponsible !== '') {
      this.authorOtherBooks()
    }
  },
  methods: {
    backToBookList () {
      this.$router.push({
        path: this.routerPath,
        query: { res: JSON.stringify(this.searchForm) }
      })
    },
    async getBooksByISBN () {
      this.loading0 = true
      this.searchBooksForm = Object.assign({}, this.searchForm)

      // if(this.searchBooksForm.StrOrganizationID !== undefined){
      //   this.searchBooksForm.StrOrganizationIDs = []
      //   this.searchBooksForm.StrOrganizationIDs[0] = this.searchBooksForm.StrOrganizationID
      // }
      this.searchBooksForm.StrOrganizationIDs = []
      this.searchBooksForm.StrOrganizationIDs[0] = localStorage.getItem('library') == null ? '' : localStorage.getItem('library')

      this.searchBooksForm.KeyName = 'StrISBN'
      this.searchBooksForm.StrKeywords = this.book.StrISBN
      this.searchBooksForm.PerPage = this.book.IntNumberCopies
      this.searchBooksForm.Page = 1
      this.searchBooksForm.Distinct = false

      const result = await getBooksByConditions(this.searchBooksForm)
      if (result.ok) {
        this.holdingInfoList = result.msg
        for (var i in this.holdingInfoList) {
          if (this.holdingInfoList[i].StrCallNumber === '') {
            this.holdingInfoTableShowStrCallNumber = false
            break
          }
        }
      } else {
        return this.$message(result.msg)
      }
      this.loading0 = false
    },
    async authorOtherBooks () {
      this.loading = true
      this.searchOtherBooksForm.StrFirstResponsible = this.book.StrFirstResponsible
      this.searchOtherBooksForm.CurrentStrISBN = this.book.StrISBN

      this.searchOtherBooksForm.StrOrganizationID = localStorage.getItem('library') == null ? '' : localStorage.getItem('library')

      const result = await getAuthorOtherBooks(this.searchOtherBooksForm)
      if (result.ok) {
        this.totalBookNum = result.total
        this.otherBooks = result.msg
        for (var i in this.otherBooks) {
          if (this.otherBooks[i].StrCallNumber === '') {
            this.authorOtherBooksTableShowStrCallNumber = false
            break
          }
        }
        // console.log(this.otherBooks)
      } else {
        return this.$message(result.msg)
      }
      this.loading = false
    },
    // 每页显示条数更改
    handleSizeChange (newSize) {
      this.searchOtherBooksForm.PerPage = newSize
      this.authorOtherBooks()
    },
    // 监听页码值改变的事件
    handleCurrentChange (newPage) {
      this.searchOtherBooksForm.Page = newPage
      this.authorOtherBooks()
    }
  }
}
</script>

<style lang="less" scoped>
.el-card {
  margin-bottom: 20px;
}

.backTop {
  margin-bottom: 20px;
}

.bookInfo {
  display: flex;

  .bookTitle {
    font-size: 20px;
    margin-bottom: 10px;
  }
}

.bookImg {
  //margin: 0 40px;
  min-width: 100px;
  height: 140px;
  overflow: hidden;
  padding-top: 20px;
  margin-right: 10px;

  img {
    width: 95px;
    height: 100px;
    object-fit: contain;
  }
}

.infoTableItem {
  font-size: 13px;
  display: flex;
}

.infoTableItemTitle {
  //width: 200px;
  margin-bottom: 4px;
}

.main_title {
  font-size: 20px;
  margin-bottom: 10px;
}

.bookBuyUrl {
  a {
    color: black;
    text-decoration: none;
  }
}

.bookBuyUrl a:hover {
  color: #3f82c4;
}

#maxBox {
  width: 100%;
  margin: 0px auto;
}
</style>
