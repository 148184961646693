// 对于axios进行二次封装
import axios from 'axios'
// 底下的代码也是创建axios实例
// 开发环境
let requests
if (process.env.NODE_ENV === 'development') {
  requests = axios.create({
    // 正式
    // baseURL: '/opac',
    // 测试
    baseURL: '/api',
    timeout: 60000
  })
}
if (process.env.NODE_ENV === 'production') {
  requests = axios.create({
    // 正式
    baseURL: '/opac',
    // 测试
    // baseURL: '/api',
    timeout: 60000
  })
}

// 响应拦截器----当服务器手动请求之后，做出响应（相应成功）会执行的
requests.interceptors.response.use(
  (res) => {
    return res.data
  }, (err) => {
    alert('服务器响应数据失败' + err)
  }
)
// 最终需要对外暴露（不对外暴露外面模块没办法使用）
// 这里的代码是暴露一个axios实例
export default requests
