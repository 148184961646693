<template>
    <div class="detailInfo">
      <top-search_phone />
      <div class="content">
        <el-card>
          <div class="selectBox" style="margin-bottom: 10px">
            <!-- <div class="selectTime">
              时间范围
              <el-select v-model="searchForm.ChooseTime" placeholder="全部" class="selectItemBox" @change="getHotBooks">
                <el-option
                  v-for="option in selectTimes"
                  :key="option.index"
                  :label="option.optionName"
                  :value="option.index">
                </el-option>
              </el-select>
            </div> -->
<!--            <div class="selectLib">-->
<!--              <el-dropdown trigger="click" @command="handleCommandFenGuan">-->
<!--                <span class="el-dropdown-link">-->
<!--                  <span ref="fenguan">分馆</span><i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--                </span>-->
<!--                <i ref="iconfenguandelete" style="margin-left: 5px;font-size: 1rem;visibility: hidden" class="el-icon-circle-close" @click="iconFenGuanDeleteClick()"/>-->
<!--                <el-dropdown-menu slot="dropdown">-->
<!--                  <div style="max-height: 400px;max-width: 250px;overflow: auto">-->
<!--                    <el-dropdown-item-->
<!--                      v-for="option in this.selectLibs"-->
<!--                      :key="option.StrID"-->
<!--                      :command="option.StrID+',.'+option.StrName">-->
<!--                      {{option.StrName}}-->
<!--                    </el-dropdown-item>-->
<!--                  </div>-->
<!--                </el-dropdown-menu>-->
<!--              </el-dropdown>-->
<!--            </div>-->
            <div class="selectClass">
              <el-dropdown trigger="click" @command="handleCommandFenLei">
                <span class="el-dropdown-link">
                  <span ref="fenlei">分类</span><i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <i ref="iconfenleidelete" style="margin-left: 5px;font-size: 1rem;visibility: hidden" class="el-icon-circle-close" @click="iconFenLeiDeleteClick()"/>
                <el-dropdown-menu slot="dropdown">
                  <div style="max-height: 400px;max-width: 250px;overflow: auto">
                    <el-dropdown-item
                      v-for="option in this.selectClasses"
                      :key="option.StrBookTypeName"
                      :command="option.StrBookTypeName + ',.' + option.Name">
                      {{option.StrBookTypeName + ' ' + option.Name}}
                    </el-dropdown-item>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-card>
<!--        手机端-->
        <el-card v-if="UseMobile" style="margin-top:10px;" v-loading="loading" element-loading-text="图书加载中...">
          <div class="showOnLibBook">
            <el-checkbox v-model="searchForm.OnLib" @change="getHotBooks">只显示在馆图书</el-checkbox>
          </div>
          <!--          <hr class="hr"/>-->
          <div class="bookListBox" v-for="(book, index) in this.bookList" :key="index">
            <div class="bookBox" @click="getBookInfo(index)">
              <div class="bookImgBox" >
                <img :src="book.StrCoverURL" alt="" style="width:6.5rem;height:7.8rem;object-fit: contain; object-position: center;">
              </div>
              <div class="bookInfoBox">
                <div class="bookTitle">{{book.StrPositiveTitle}}</div>
                <div class="bookInfo">
                  <div class="bookInfoLeft">
                    <div>责任者：{{book.StrFirstResponsible}}</div>
                    <div>出版社：{{book.StrPublisher}}</div>
                    <div>ISBN：{{book.StrISBN}}</div>
                    <div>分类号：{{book.StrTypeName}}</div>
                    <div>种次号：{{book.StrCallNumber}}</div>
                    <div>出版时间：{{book.StrPublicationDate}}</div>
                    <div>关键词：{{book.StrKeywords}}</div>
                  </div>
                  <!--                    <div class="bookInfoRight">-->
                  <!--                      -->
                  <!--                    </div>-->
                </div>
                <div class="bookPreview">
                  <div class="libCollection">馆藏({{book.IntNumberCopies}})</div>
                </div>
              </div>
            </div>
          </div>
          <div style="max-width: 100%;height: 35px;overflow: auto">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" small hide-on-single-page
                         :current-page="searchForm.Page" :page-size="searchForm.PerPage" layout="pager,jumper" :total="totalBookNum" :pager-count="5">
          </el-pagination>
          </div>
        </el-card>
<!--        电脑端-->
        <el-card v-else style="margin-top:10px;" v-loading="loading" element-loading-text="图书加载中...">
          <div class="showOnLibBook2">
            <el-checkbox v-model="searchForm.OnLib" @change="getHotBooks">只显示在馆图书</el-checkbox>
          </div>
          <hr class="hr"/>
          <div class="bookListBox2" v-for="(book, index) in this.bookList" :key="index">
            <div class="bookBox2" @click="getBookInfo(index)">
              <div class="bookImgBox2" >
                <img :src="book.StrCoverURL" alt="" style="width:104px;height: 144px;object-fit: contain;object-position: center;">
              </div>
              <div class="bookInfoBox2">
                <div class="bookTitle2">{{book.StrPositiveTitle}}</div>
                <div class="bookInfo2">
                  <div class="bookInfoLeft2">
                    <div>责任者：{{book.StrFirstResponsible}}</div>
                    <div>出版社：{{book.StrPublisher}}</div>
                    <div>ISBN：{{book.StrISBN}}</div>
                    <div>借阅次数：{{book.IntBorrowedCount}}</div>
                  </div>
                  <div class="bookInfoRight2">
                    <div>出版时间：{{book.StrPublicationDate}}</div>
                    <div>关键词：{{book.StrKeywords}}</div>
                    <div>分类号：{{book.StrTypeName}}</div>
                    <div>种次号：{{book.StrCallNumber}}</div>
                  </div>
                </div>
                <div class="bookPreview2">
                  <div class="libCollection2">馆藏({{book.IntNumberCopies}})</div>
                </div>
              </div>
            </div>
          </div>

            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page="searchForm.Page" :page-sizes="[10, 20, 30, 50]"
                           hide-on-single-page :page-size="searchForm.PerPage" layout="sizes, prev, pager, next, jumper" :total="totalBookNum">
            </el-pagination>
        </el-card>
      </div>
    </div>
</template>
<script>

import { getHotBooks, getAllLibs, getClcType } from '@/api'
import TopSearch_phone from '@/components/TopSearch/index_phone'
export default {
  components: { TopSearch_phone },
  data () {
    return {
      loading: false,
      UseMobile: false,
      imgUrl: 'https://next.sanyue100.com/covers/',
      selectLibs: [],
      selectClasses: [
      //   {
      //   index: '',
      //   optionName: ''
      // }
      ],
      selectTimes: [{
        index: 0,
        optionName: '全部'
      },
      {
        index: -7,
        optionName: '最近一周'
      },
      {
        index: -30,
        optionName: '最近一月'
      },
      {
        index: -90,
        optionName: '最近三月'
      },
      {
        index: -180,
        optionName: '最近半年'
      },
      {
        index: -365,
        optionName: '最近一年'
      }],
      searchForm: {
        OnLib: false,
        Page: 1,
        PerPage: 10,
        CLCCheck: '',
        ChooseTime: 0
      },
      bookList: [],
      totalBookNum: 0
    }
  },
  created () {
    this.UseMobile = document.body.clientWidth < 620
    this.searchForm.StrOrganizationID = localStorage.getItem('library') == null ? '' : localStorage.getItem('library')
    // this.getAllLibs()
    this.getHotBooks()
    this.getClc()
  },
  methods: {
    // 点击分馆删除图标
    iconFenGuanDeleteClick () {
      this.$refs.fenguan.innerText = '分馆'
      this.searchForm.StrOrganizationID = ''
      this.$refs.iconfenguandelete.style.visibility = 'hidden'
      this.getHotBooks()
    },
    // 点击下拉菜单项的回调
    handleCommandFenGuan (command) {
      this.$refs.fenguan.innerText = command.split(',.')[1]
      this.searchForm.StrOrganizationID = command.split(',.')[0]
      this.$refs.iconfenguandelete.style.visibility = 'visible'
      this.getHotBooks()
    },
    // 点击分类删除图标
    iconFenLeiDeleteClick () {
      this.$refs.fenlei.innerText = '分类'
      this.searchForm.CLCCheck = ''
      this.$refs.iconfenleidelete.style.visibility = 'hidden'
      this.getHotBooks()
    },
    // 点击下拉菜单项的回调
    handleCommandFenLei (command) {
      this.$refs.fenlei.innerText = command.split(',.')[0] + ' ' + command.split(',.')[1]
      this.searchForm.CLCCheck = command.split(',.')[0]
      this.$refs.iconfenleidelete.style.visibility = 'visible'
      this.getHotBooks()
    },
    async getAllLibs () {
      const result = await getAllLibs()
      if (result.ok) {
        this.selectLibs = result.msg
        // console.log(this.libraryAddresses)
      } else {
        return this.$message(result.msg)
      }
    },
    async getClc () {
      const result = await getClcType()
      if (result.ok) {
        this.selectClasses = result.msg
      } else {
        return this.$message(result.msg)
      }
    },
    async getHotBooks () {
      this.loading = true
      this.searchForm.Page = 1
      const result = await getHotBooks(this.searchForm)
      if (result.ok) {
        this.bookList = result.msg
        // this.selectClasses = result.ClC
        this.totalBookNum = result.total
        for (var i in this.bookList) {
          this.getImg(this.bookList[i])
        }
        // console.log(this.libraryAddresses)
      } else {
        return this.$message(result.msg)
      }
      this.loading = false
    },
    // 每页显示条数更改  不会使用到
    handleSizeChange (newSize) {
      this.searchForm.PerPage = newSize
      this.getHotBooks()
    },

    // 监听页码值改变的事件
    handleCurrentChange (newPage) {
      this.searchForm.Page = newPage
      this.getHotBooksPageChange()
    },
    // 页码改变专用
    async getHotBooksPageChange () {
      this.loading = true
      const result = await getHotBooks(this.searchForm)
      if (result.ok) {
        this.bookList = result.msg
        // this.selectClasses = result.ClC
        this.totalBookNum = result.total
        for (var i in this.bookList) {
          this.getImg(this.bookList[i])
        }
        // console.log(this.libraryAddresses)
      } else {
        return this.$message(result.msg)
      }
      this.loading = false
    },

    getBookInfo (index) {
      var book = this.bookList[index]
      this.$router.push({
        path: '/bookInfo',
        query: { res: JSON.stringify(this.searchForm), book: JSON.stringify(book), router: 'hotBook' }
      })
    }
  }
}
</script>

<style lang="less" scoped>
//下拉菜单
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
  font-size: 0.9rem;
}
.el-icon-arrow-down {
  font-size: 0.9rem;
}
.el-dropdown-menu__item, .el-menu-item{
  font-size: 0.9rem;
}
//单选框
.el-checkbox{
  font-size: 0.85rem;
}
//.el-checkbox__inner{
//  width: 20px;
//  height: 20px;
//}

.content{
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
}
.selectBox{
  display: flex;
  align-items: center;
}
.selectItemBox {
  width: 120px;
}
.timeSelectBox{
  margin-left:10px;
  width:280px !important;
}
.selectLib, .selectClass{
  width: 50%;
  //margin-left: 20px;
}
.hr{
  background-color: #e5e5e5;
  height:1px;
  border:none;
  margin: 16px 0 30px 0;
}
.bookListBox {
  //margin-bottom: 14px;
}
.bookBox {
  padding-top: 7%;
  padding-bottom: 7%;
  //height: 170px;
  display: flex;
  border-bottom: #e5e5e5 1px solid;
  cursor: pointer;
  .bookImgBox {
    margin-top: 1.3rem;
    margin-right: 0.5rem;
    height: 9rem;
  }

  .bookTitle{
    font-weight: 700;
    margin-bottom: 6%;
    line-height: 1.25;
  }
}
.bookPreview {
  display: flex;
}
.bookInfo {
  color: #61666d;
  font-size: 0.8rem;
  margin-bottom: 10px;
  display: flex;
  div{
    margin-bottom: 2px;
  }

}
.libCollection {
  color: #409eff;
  font-size: 0.85rem;
}

//电脑端
.bookListBox2 {
  margin-bottom: 14px;
}
.bookBox2 {
  padding: 30px;
  height: 170px;
  display: flex;
  border-bottom: #e5e5e5 1px solid;
  cursor: pointer;
  .bookImgBox2 {
    flex: 1;
    height: 140px;
  }
  .bookInfoBox2 {
    flex: 4;
  }
  .bookTitle2{
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 1.25;
  }
}
.bookPreview2 {
  display: flex;
}
.bookInfo2 {
  color: #61666d;
  font-size: 14px;
  margin-bottom: 18px;
  display: flex;
  div{
    margin-bottom: 2px;
  }
  .bookInfoRight2{
    flex: 5;
  }
  .bookInfoLeft2 {
    flex: 4;
  }
}
.libCollection2 {
  color: #409eff;
  font-size: 16px;
}
</style>
