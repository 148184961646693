<template>
    <div class="browse">
      <topSearch  @fatherMethod="fatherMethod"/>
      <div class="classSearch">
        <el-card>
          <div>
            <div>分类</div>
            <template>
              <el-row :gutter="20">
                <el-col :span="4" v-for=" (item, index) in cLcBrowse" :key="index" :class="{active:index==clcTypeCheck}">
                  <div class="clcTypeBox" @click="setTypeName(item.StrBookTypeName,index)">
                    <div class="strTypeName">{{item.StrBookTypeName}} {{item.Name}}</div>
                    <div>({{item.TotalISBN}})</div>
                  </div>
                </el-col>
              </el-row>
            </template>
          </div>
        </el-card>
      </div>
      <div class="content">
        <div class="selectListBox">
          <el-card>
            <div class="selectedChoose">
              <div>已选</div>
              <el-tag
                :key="index"
                v-for="(tag, key, index) in selectedChoosesTag"
                closable
                :disable-transitions="false"
                @close="handleCloseTag(key)" class="tagBox" effect="plain">
                {{tag}}
              </el-tag>
            </div>
            <div class="menuChoose">
                <el-menu :default-openeds='["libs"]' @select="selectedAndTagAdd" ref="libs" v-if="sidebarBranch">
                  <el-submenu index="libs">
                    <template slot="title">
                      <span class="menuTitle">分馆</span>
                    </template>
                    <div style="max-height: 500px;overflow: auto;">
                    <el-menu-item v-for="(item, i) in selectedChooses.libs" :key="item.Name" :index="i.toString()"  style="padding-left: 25px !important;
                        ">{{item.Name}}({{item.Total}})</el-menu-item>
                    </div>
                  </el-submenu>
                </el-menu>
                <el-menu :default-openeds='["authors"]' @select="selectedAndTagAdd" :default-active="authors" ref="authors" >
                  <el-submenu index="authors">
                    <template slot="title">
                      <span class="menuTitle">责任人</span>
                    </template>
                    <div style="max-height: 500px;overflow: auto;">
                      <el-menu-item v-for="(item, i) in selectedChooses.authors" :key="item.Name" style="padding-left: 25px !important;" :index="i.toString()">{{item.Name}}({{item.Total}})</el-menu-item>
                    </div>
                  </el-submenu>
                </el-menu>
                <el-menu :default-openeds='["publishers"]' @select="selectedAndTagAdd" :default-active="publishers"  ref="publishers">
                  <el-submenu index="publishers">
                      <template slot="title">
                        <span class="menuTitle">出版社</span>
                      </template>
                    <div style="max-height: 500px;overflow: auto;">
                      <el-menu-item v-for="(item, i) in selectedChooses.publishers" :key="item.Name" style="padding-left: 25px !important;" :index="i.toString()">{{item.Name}}({{item.Total}})</el-menu-item>
                    </div>
                    </el-submenu>
                </el-menu>
                <el-menu :default-openeds='["publications"]' @select="selectedAndTagAdd" :default-active="publications" ref="publications">
                  <el-submenu index="publications">
                      <template slot="title">
                        <span class="menuTitle">出版时间</span>
                      </template>
                    <div style="max-height: 500px;overflow: auto;">
                      <el-menu-item v-for="(item, i) in selectedChooses.publications" :key="item.Name" style="padding-left: 25px !important;" :index="i.toString()">{{item.Name}}({{item.Total}})</el-menu-item>
                    </div>
                    </el-submenu>
                </el-menu>
            </div>
          </el-card>
        </div>
        <div class="bookListsBox" v-loading="loading" element-loading-text="图书加载中...">
          <el-card>
            <div class="bookListTop">共{{totalBookNum}}条数据</div>
            <div class="bookListSelect">
              <div class="sortBox">
                <div class="sortSelect">
                  排序选项
                  <el-select v-model="searchForm.OrderBy" placeholder="默认排序" class="sortSelectChooseBox" @change="getBookList">
                    <el-option
                      v-for="option in sortSelects"
                      :key="option.index"
                      :label="option.optionName"
                      :value="option.index">
                    </el-option>
                  </el-select>
                </div>
                <div class="sortOrder">
                  排序方式
                  <el-select v-model="searchForm.Sort" placeholder="升序" class="sortOrderChooseBox" @change="getBookList">
                    <el-option
                      v-for="option in sortOrders"
                      :key="option.index"
                      :label="option.optionName"
                      :value="option.index">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="showOnLibBook">
                <!-- `checked` 为 true 或 false -->
                <el-checkbox v-model="searchForm.OnLib" @change="getBookList">只显示在馆图书</el-checkbox>
              </div>
            </div>
            <hr class="hr"/>
            <div class="bookListBox" v-for="(book, index) in this.bookList" :key="index">
              <div class="bookBox" @click="getBookInfo(index)">
                <div class="bookImgBox" >
                  <img :src="book.StrCoverURL" alt="" style="width:104px;height: 144px;object-fit: contain;object-position: center;">
                </div>
                <div class="bookInfoBox">
                  <div class="bookTitle">{{book.StrPositiveTitle}}</div>
                  <div class="bookInfo">
                    <div class="bookInfoLeft">
                      <div>责任者：{{book.StrFirstResponsible}}</div>
                      <div>出版社：{{book.StrPublisher}}</div>
                      <div>ISBN：{{book.StrISBN}}</div>
                      <div>分类号：{{book.StrTypeName}}</div>
                    </div>
                    <div class="bookInfoRight">
                      <div>出版时间：{{book.StrPublicationDate}}</div>
                      <div>关键词：{{book.StrKeywords}}</div>
                      <div>种次号：{{book.StrCallNumber}}</div>
                    </div>
                  </div>
                  <div class="bookPreview">
                    <div class="libCollection">馆藏({{book.IntNumberCopies}})</div>
                  </div>
                </div>
              </div>
            </div>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           hide-on-single-page :current-page="searchForm.Page" :page-sizes="[10, 20, 30, 50]"
                           :page-size="searchForm.PerPage" layout="sizes, prev, pager, next, jumper" :total="totalBookNum">
            </el-pagination>
          </el-card>
        </div>
      </div>
    </div>
</template>

<script>
import { getBooksByConditions, getBooksOthers } from '@/api'
export default {
  data () {
    return {
      loading: true,
      imgUrl: 'https://next.sanyue100.com/covers/',
      bookList: [],
      selectedChooses: {
        libs: [],
        authors: [],
        publishers: [],
        publications: []
      },
      sortSelects: [{
        index: 'DTCreatedDate',
        optionName: '默认排序'
      },
      {
        index: 'StrPositiveTitle',
        optionName: '书名'
      },
      {
        index: 'StrFirstResponsible',
        optionName: '责任人'
      },
      {
        index: 'StrPublisher',
        optionName: '出版社'
      },
      {
        index: 'StrPublicationDate',
        optionName: '出版日期'
      }],
      sortOrders: [{
        index: '-1',
        optionName: '降序'
      },
      {
        index: '1',
        optionName: '升序'
      }],
      totalBookNum: 0,
      selectedChoosesTag: {},
      searchForm: {
        Page: 1,
        PerPage: 10
      },
      cLcBrowse: [],
      clcTypeCheck: -1,
      libs: '',
      authors: '',
      publishers: '',
      publications: '',
      // 侧边栏是否需要分馆
      sidebarBranch: false
    }
  },
  created () {
    this.searchForm = JSON.parse(this.$route.query.res)
    // 书本不重复显示
    this.getBookList()
    this.getOtherInfo()
    // console.log(this.searchForm.authors)
    if (this.searchForm.libs !== '' && this.searchForm.libs !== undefined) {
      this.$set(this.selectedChoosesTag, 'libs', this.searchForm.libs)
    }
    if (this.searchForm.authors !== '' && this.searchForm.authors !== undefined) {
      this.$set(this.selectedChoosesTag, 'authors', this.searchForm.authors)
    }
    if (this.searchForm.publishers !== '' && this.searchForm.publishers !== undefined) {
      this.$set(this.selectedChoosesTag, 'publishers', this.searchForm.publishers)
    }
    if (this.searchForm.publications !== '' && this.searchForm.publications !== undefined) {
      this.$set(this.selectedChoosesTag, 'publications', this.searchForm.publications)
    }
    if (localStorage.getItem('library') === '' || localStorage.getItem('library') === null) {
      this.sidebarBranch = false
    }
  },
  methods: {
    // 搜索框点击搜索按钮时调用方法
    fatherMethod () {
      this.searchForm = JSON.parse(this.$route.query.res)
      // 书本不重复显示
      this.getBookList()
      this.getOtherInfo()
      if (this.searchForm.libs !== '' && this.searchForm.libs !== undefined) {
        this.$set(this.selectedChoosesTag, 'libs', this.searchForm.libs)
      }
      if (this.searchForm.authors !== '' && this.searchForm.authors !== undefined) {
        this.$set(this.selectedChoosesTag, 'authors', this.searchForm.authors)
      }
      if (this.searchForm.publishers !== '' && this.searchForm.publishers !== undefined) {
        this.$set(this.selectedChoosesTag, 'publishers', this.searchForm.publishers)
      }
      if (this.searchForm.publications !== '' && this.searchForm.publications !== undefined) {
        this.$set(this.selectedChoosesTag, 'publications', this.searchForm.publications)
      }
    },
    async getBookList () {
      this.loading = true
      this.searchForm.Page = 1
      const result = await getBooksByConditions(this.searchForm)
      if (result.ok) {
        this.bookList = result.msg
        this.totalBookNum = result.total
        for (var i in this.bookList) {
          this.getImg(this.bookList[i])
        }
      } else {
        return this.$message(result.msg)
      }
      this.loading = false
    },
    async getOtherInfo () {
      const result = await getBooksOthers(this.searchForm)
      if (result.ok) {
        this.cLcBrowse = result.CLC
        this.selectedChooses.libs = result.Libs
        this.selectedChooses.authors = result.Authors
        this.selectedChooses.publishers = result.Publishers
        this.selectedChooses.publications = result.Publications
      } else {
        return this.$message(result.msg)
      }
    },
    setTypeName (typeName, index) {
      this.clcTypeCheck = index
      this.searchForm.CLCCheck = typeName
      this.getBookList()
      this.getOtherInfo()
    },
    handleCloseTag (key) {
      this.$delete(this.selectedChoosesTag, key)
      this.libs = '1'
      this.$refs[key].activeIndex = null
      this.searchForm[key] = ''
      this.getBookList()
      this.getOtherInfo()
    },
    selectedAndTagAdd (key, keyPath) {
      var className = keyPath[0]
      this.$set(this.selectedChoosesTag, className, this.selectedChooses[className][key].Name)
      this.searchForm[className] = this.selectedChooses[className][key].Name
      this.getBookList()
      this.getOtherInfo()
    },
    // 每页显示条数更改
    handleSizeChange (newSize) {
      this.searchForm.PerPage = newSize
      this.getBookListPageChange()
    },
    // 监听页码值改变的事件
    handleCurrentChange (newPage) {
      this.searchForm.Page = newPage
      this.getBookListPageChange()
    },
    async getBookListPageChange () {
      this.loading = true
      const result = await getBooksByConditions(this.searchForm)
      if (result.ok) {
        this.bookList = result.msg
        this.totalBookNum = result.total
        for (var i in this.bookList) {
          this.getImg(this.bookList[i])
        }
      } else {
        return this.$message(result.msg)
      }
      this.loading = false
    },

    getBookInfo (index) {
      var book = this.bookList[index]
      this.$router.push({
        path: '/bookInfo',
        query: { res: JSON.stringify(this.searchForm), book: JSON.stringify(book), router: 'bookList' }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.searchTop {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.searchItem{
  margin-right: 20px;
}
.searchSelect{
  width: 160px;
}
.searchInput{
  width: 400px;
}
.classSearch {
  width: 1092px;
  margin: 20px auto 0px auto;
}
.content{
  width: 1092px;
  display: flex;
  margin: 0 auto;
  margin-top: 20px;
}
.selectListBox{
  margin-right: 10px;
  width: 260px;
  /deep/.el-card__body {
    padding: 0;
  }
}
.bookListsBox{
  margin-left: 10px;
  min-width: 812px;
}
.selectLib {
  width: 240px;
  margin: 20px 0 auto;
}
.bookListSelect{
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sortBox {
  display: flex;
}
.sortSelect{
  margin-right: 20px;
}
.sortSelectChooseBox{
  margin-left: 10px;
  width: 140px;
}
.sortOrderChooseBox{
  margin-left: 10px;
  width: 100px;
}
.hr{
  background-color: #e5e5e5;
  height:1px;
  border:none;
  margin: 30px 0 0 0;
}

.el-menu {
  border: none;
}
.menuTitle {

  font-size: 16px;
  font-weight: 700;
}
.el-submenu__title {
  height: 40px !important;
}
.el-submenu {
  background-color: #f2f2f2;
}
.selectedChoose {
  margin: 20px;
}
.tagBox {
  margin: 5px;
}
.bookBox {
  padding: 30px;
  height: 170px;
  display: flex;
  border-bottom: #e5e5e5 1px solid;
  cursor: pointer;
  .bookImgBox {
    flex: 1;
    height: 140px;
  }
  .bookInfoBox {
    flex: 4;
  }
  .bookTitle{
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 1.25;
  }
}
.bookPreview {
  display: flex;
}
.bookInfo {
  color: #61666d;
  font-size: 14px;
  margin-bottom: 18px;
  display: flex;
  div{
    margin-bottom: 2px;
  }
  .bookInfoRight{
    flex: 5;
  }
  .bookInfoLeft {
    flex: 4;
  }
}
.libCollection {
  color: #409eff;
  font-size: 14px;
}
.bookListBox {
  margin-bottom: 14px;
}
.el-row {
  margin-top: 6px;
}
.el-col {
  display: flex;
  .clcTypeBox {
    padding:0 14px;
    width: 100%;
    height: 34px;
    line-height: 34px;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    border-radius: 6px;
    align-items: center;
    cursor: pointer;
    .strTypeName {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.clcTypeBox:hover{
  background-color: #f2f3f5;
}
.active{
  .clcTypeBox {
    background-color: #f2f3f5;
  }
}
</style>
