// 统一管理项目接口的模块
// 引入二次封装的axios（带有请求、响应的拦截器）
import requests from './ajax'
// 三级菜单的请求地址  /api/product/getBaseCategoryList   GET    没有任何参数
// 对外暴露一个函数，只要外部调用这个函数，就想服务器发起ajax请求、获取咱们的三级菜单数据。当前咱们这个函数只需要把服务器返回结果返回即可。
export const getAllLibs = () => requests({ url: '/getAllLibs', method: 'get' })
export const getBooksByConditions = (data) => requests({ url: '/getBooksByConditions', method: 'post', data })
export const getBooksOthers = (data) => requests({ url: '/getBooksOthers', method: 'post', data })
export const getAuthorOtherBooks = (data) => requests({ url: '/getAuthorOtherBooks', method: 'post', data })
export const getNewBooks = (data) => requests({ url: '/getNewBooks', method: 'post', data })
export const getHotBooks = (data) => requests({ url: '/getHotBooks', method: 'post', data })
export const getClcType = (data) => requests({ url: '/getClcType', method: 'get', data })
