<template>
  <div class="index">
    <div id="settingdiv">
      <el-button v-if="settingIsIcon" type="text" icon="el-icon-s-tools" circle @click="setting()" > </el-button>
              <el-button v-else type="text" style="font-size: 12px;margin-right: 5px;" @click="setting()">{{libName}}</el-button>
    </div>
    <div class="headerLogo">

      <img src="../assets/img/indexlogo.png" alt="" width="100%">
    </div>
    <el-dialog
      title="设置"
      :show-close="this.showClose"
      :visible.sync="dialogVisible"
      width="80%">
      <span style="margin-left: 10px">组织</span>
      <el-select v-model="searchForm.StrOrganizationIDs[0]" placeholder="请选择组织" clearable style="margin-left: 10px;max-width: 70%" >
        <el-option
          v-for="address in libraryAddresses"
          :key="address.StrID"
          :label="address.StrName"
          :value="address.StrID">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="sureChange()">确 定</el-button>
      </span>
    </el-dialog>
    <div class="search">
      <!-- 书名 下拉选择-->
      <!--      <div class="libraryAddress searchFind">   &lt;!&ndash;未设置宽度&ndash;&gt;-->
      <!--        <el-select v-model="searchForm.StrOrganizationIDs[0]" placeholder="请选择" @change="selectchange()"  clearable>-->
      <!--          <el-option-->
      <!--            v-for="address in libraryAddresses"-->
      <!--            :key="address.StrID"-->
      <!--            :label="address.StrName"-->
      <!--            :value="address.StrID">-->
      <!--          </el-option>-->
      <!--        </el-select>-->
      <!--      </div>-->
      <!--据什么查询-->
      <div class="searchItem searchFind">
        <el-select v-model="searchForm.KeyName" placeholder="请选择" @change="selectKeyNamechange()">
          <el-option
            v-for="option in optionSelects"
            :key="option.index"
            :label="option.optionName"
            :value="option.index">
          </el-option>
        </el-select>
      </div>
      <div class="searchInput searchItem">
        <el-input v-model="searchForm.StrKeywords" placeholder="请输入关键词"
                  @keydown.enter.native="getSearchBookListToBookList" type="search"></el-input>
      </div>
      <div class="searchButton searchItem" style="padding-top: 0.5%">
        <el-button type="primary" size="medium" icon="el-icon-search" circle
                   @click="getSearchBookListToBookList"></el-button>
        <!--        <el-button type="primary" @click="getSearchBookListToBookList" style="border: 1px;">检索</el-button>-->
        <!--        <el-button type="primary" @click="advancedSearchBoxFun">高级检索</el-button>-->
      </div>
    </div>

    <!-- 高级检索条件  -->
    <!--    <div class="advancedSearchBox" v-show="this.advancedSearchBoxIsTrue">-->
    <!--      <el-card>-->
    <!--        <el-button @click="addDomain" class="addDomainButton">新增检索条件</el-button>-->
    <!--        <el-form :model="advancedSearchForm" ref="advancedSearchForm" label-width="100px">-->
    <!--          <el-form-item-->
    <!--            v-for="(item, index) in advancedSearchForm.items"-->
    <!--            :key="index">-->
    <!--            <div class="itemBox">-->
    <!--              <div class="itemBoxStyle">-->
    <!--                <el-select v-model="item.searchSelectDefaultValue" placeholder="书名" style="width:110px;" class="itemBoxEl">-->
    <!--                  <el-option v-for="option in optionSelects" :key="option.index" :label="option.optionName" :value="option.index"></el-option>-->
    <!--                </el-select>-->
    <!--                <el-input v-model="item.value" style="width:460px" class="itemBoxEl itemBoxInput"></el-input>-->
    <!--                <el-select v-model="item.searchSelectOrAndValue" class="itemBoxEl" style="width:110px;" >-->
    <!--                  <el-option v-for="option in searchSelectOrAnd" :key="option.index" :label="option.optionName" :value="option.index"></el-option>-->
    <!--                </el-select>-->
    <!--              </div>-->
    <!--              <el-button @click.prevent="removeDomain(item)">删除</el-button>-->
    <!--            </div>-->
    <!--          </el-form-item>-->
    <!--          <el-form-item>-->
    <!--            <span class="timeText">出版时间</span>-->
    <!--            <el-date-picker-->
    <!--              v-model="advancedSearchForm.StrPublicationDate[0]"-->
    <!--              type="year"-->
    <!--              placeholder="选择年" style="width:150px;">-->
    <!--            </el-date-picker>-->
    <!--            <span class="separator">-</span>-->
    <!--            <el-date-picker-->
    <!--              v-model="advancedSearchForm.StrPublicationDate[1]"-->
    <!--              type="year"-->
    <!--              placeholder="选择年" style="width:150px;">-->
    <!--            </el-date-picker>-->
    <!--            &lt;!&ndash; <el-date-picker-->
    <!--                v-model="advancedSearchForm.StrPublicationDate"-->
    <!--                type="monthrange"-->
    <!--                range-separator="-"-->
    <!--                start-placeholder="开始月份"-->
    <!--                end-placeholder="结束月份" class="timeSelectBox">-->
    <!--              </el-date-picker> &ndash;&gt;-->
    <!--          </el-form-item>-->
    <!--          <el-form-item>-->
    <!--            <span class="timeText">分馆检索</span>-->
    <!--            <template>-->
    <!--              <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>-->
    <!--              <div style="margin: 15px 0;"></div>-->
    <!--              <el-checkbox-group v-model="advancedSearchForm.StrOrganizationIDs" @change="handleCheckedLibraryChange">-->
    <!--                <el-checkbox v-for="library in libraryAddresses" :label="library.StrID" :key="library.StrID">{{library.StrName}}</el-checkbox>-->
    <!--              </el-checkbox-group>-->
    <!--            </template>-->
    <!--          </el-form-item>-->
    <!--          <el-form-item>-->
    <!--            <el-button type="primary" @click="getAdvancedSearchBookListToBookList">检索</el-button>-->
    <!--          </el-form-item>-->
    <!--        </el-form>-->
    <!--      </el-card>-->
    <!--    </div>-->
  </div>
</template>
<script>
import { getAllLibs } from '@/api'

export default {
  data () {
    return {
      dialogVisible: false,
      showClose: false,
      settingIsIcon: true,
      libName: '',
      libraryAddresses: [],
      optionSelects: [
        {
          index: 'all',
          optionName: '任意词'
        },
        {
          index: 'StrPositiveTitle',
          optionName: '书名'
        }, {
          index: 'StrFirstResponsible',
          optionName: '作者'
        }, {
          index: 'StrISBN',
          optionName: 'ISBN'
        }, {
          index: 'StrPublisher',
          optionName: '出版社'
        }],
      searchSelectOrAnd: [{
        index: 'or',
        optionName: '或(OR)'
      }, {
        index: 'and',
        optionName: '和(AND)'
      }],
      searchForm: {
        KeyName: 'all',
        StrKeywords: '',
        Sort: '-1',
        OrderBy: 'DTCreatedDate',
        OnLib: false,
        Distinct: true,
        Page: 1,
        PerPage: 10,
        StrOrganizationIDs: []
      },
      // 控制高级检索条件是否显示
      advancedSearchBoxIsTrue: false,
      advancedSearchForm: {
        items: [{
          value: '',
          searchSelectDefaultValue: '书名',
          searchSelectOrAndValue: 'or'
        }],
        StrOrganizationIDs: [],
        StrPublicationDate: [],
        Sort: '-1',
        OnLib: false,
        Distinct: true,
        OrderBy: 'DTCreatedDate',
        Page: 1,
        PerPage: 10
      },
      isIndeterminate: false,
      checkAll: false
    }
  },
  mounted () {
    this.searchLibraryAddresses()
    this.searchForm.StrOrganizationIDs[0] = localStorage.getItem('library') == null ? '' : localStorage.getItem('library')
    this.searchForm.StrKeywords = sessionStorage.getItem('searchContent') == null ? '' : sessionStorage.getItem('searchContent')
    const keyname = sessionStorage.getItem('keyName')
    if (keyname !== null && keyname !== '') {
      this.searchForm.KeyName = keyname
    } else {
      this.searchForm.KeyName = 'all'
    }
  },

  methods: {
    // async searchLibraryAddresses () {
    //   // const { data: res } = await this.$http.get('search/list')
    //   // if (res.meta.status !== 200) {
    //   //   return this.$message.console.error(res.meta.msg)
    //   // }
    //   // console.log(res.data)
    //   // this.libraryAddresses = res.data
    //   const result = await getAllLibs()
    //   if (result.ok) {
    //     this.libraryAddresses = result.msg
    //   } else {
    //     return this.$message(result.msg)
    //   }
    // },
    advancedSearchBoxFun () {
      this.advancedSearchBoxIsTrue = !this.advancedSearchBoxIsTrue
    },
    // submitForm (formName) {
    //   this.$refs[formName].validate((valid) => {
    //     if (valid) {
    //       alert('submit!')
    //     } else {
    //       console.log('error submit!!')
    //       return false
    //     }
    //   })
    // },
    // resetForm (formName) {
    //   this.$refs[formName].resetFields()
    // },
    // selectchange () {
    //   localStorage.setItem('library', this.searchForm.StrOrganizationIDs[0])
    // },
    selectKeyNamechange () {
      sessionStorage.setItem('keyName', this.searchForm.KeyName)
    },
    removeDomain (item) {
      var index = this.advancedSearchForm.items.indexOf(item)
      if (index !== -1) {
        this.advancedSearchForm.items.splice(index, 1)
      }
    },
    addDomain () {
      this.advancedSearchForm.items.push({
        value: '',
        searchSelectDefaultValue: '书名',
        searchSelectOrAndValue: 'or'
      })
      // console.log(this.advancedSearchForm)
    },
    handleCheckAllChange (checkAllValue) {
      if (checkAllValue) {
        for (var i = 0; i < this.libraryAddresses.length; i++) {
          this.advancedSearchForm.StrOrganizationIDs.push(this.libraryAddresses[i].StrID)
        }
      } else {
        this.advancedSearchForm.StrOrganizationIDs = []
      }
      this.isIndeterminate = false
    },
    handleCheckedLibraryChange (value) {
      // console.log(value)
      var checkedCount = value.length
      this.checkAll = checkedCount === this.libraryAddresses.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.libraryAddresses.length
    },
    getSearchBookListToBookList () {
      // 去除两端的空格，防止出现因为空格导致搜索不到的问题
      this.searchForm.StrKeywords = this.searchForm.StrKeywords.trim()
      const keyword = this.searchForm.StrKeywords
      if (keyword === '' || keyword == null) {
        alert('请输入搜索词')
        return
      }
      // 这里尝试获取一次libID，防止因为在当前页面更改而造成发送的请求未更新libID
      this.searchForm.StrOrganizationIDs[0] = localStorage.getItem('library') == null ? '' : localStorage.getItem('library')
      sessionStorage.setItem('keyName', this.searchForm.KeyName)
      sessionStorage.setItem('searchContent', this.searchForm.StrKeywords)
      this.$router.push({
        path: '/bookList',
        query: { res: JSON.stringify(this.searchForm) }
      })
    },
    getAdvancedSearchBookListToBookList () {
      var items = this.advancedSearchForm.items
      if (items.length === 0) {
        alert('请输入搜索词')
        return
      }
      var item0 = items[0]
      if (item0.value === '') {
        alert('请输入搜索词')
        return
      }

      // if (this.searchForm.StrKeywords === '') {
      //   alert('请输入搜索词')
      //   return
      // }
      this.$router.push({
        path: '/bookList',
        query: { res: JSON.stringify(this.advancedSearchForm) }
      })
    },
    sureChange () {
      localStorage.setItem('library', this.searchForm.StrOrganizationIDs[0])
      this.changeLib()
      this.dialogVisible = false
    },
    async searchLibraryAddresses () {
      // const { data: res } = await this.$http.get('search/list')
      // if (res.meta.status !== 200) {
      //   return this.$message.console.error(res.meta.msg)
      // }
      // console.log(res.data)
      // this.libraryAddresses = res.data
      const result = await getAllLibs()
      if (result.ok) {
        this.libraryAddresses = result.msg
        this.changeLib()
      } else {
        return this.$message(result.msg)
      }
    },
    changeLib () {
      this.searchForm.StrOrganizationIDs[0] = localStorage.getItem('library') == null ? '' : localStorage.getItem('library')
      if (this.searchForm.StrOrganizationIDs[0] != null && this.searchForm.StrOrganizationIDs[0] !== '') {
        for (var i in this.libraryAddresses) {
          if (this.libraryAddresses[i].StrID == this.searchForm.StrOrganizationIDs[0]) {
            this.libName = this.libraryAddresses[i].StrName
            this.settingIsIcon = false
          }
        }
      } else {
        this.libName = ''
        this.settingIsIcon = true
      }
    },
    // 判断是否为移动端
    isMobile () {
      return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    },
    setting () {
      this.dialogVisible = true
    }
  }
}
</script>
<style lang="less" scoped>
.index {
  width: 100%;
  margin: 0 auto;
}

.headerLogo {
  position: relative;
  margin-top: 20%;
  width: 70%;
  margin-left: 15%;
}

.search {
  margin-top: 25%;
  padding: 0 1%;
  display: flex;
  justify-content: center;
}

.searchItem {
  //margin: 0 8px;
  margin-left: 1%;
}

.searchFind {
  width: 25%;
}

.searchInput {
  width: 60%;
}

.advancedSearchBox {
  margin-top: 30px;
}

.itemBoxStyle {
  display: inline-block;
  width: 680px;
  border: 1px solid #cccccc;
  margin-right: 20px;
  height: 40px;
  line-height: 40px;
}

/* 利用穿透，设置input边框隐藏 */
.itemBoxEl /deep/ .el-input__inner {
  border: none;
  height: 30px !important;
}

.itemBoxInput /deep/ .el-input__inner {
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-radius: 0;
}

.el-form-item {
  width: 960px;
  margin: 0 auto 20px auto;
}

.addDomainButton {
  margin-left: 140px;
  margin-bottom: 20px;
}

.timeText {
  margin-right: 20px;
}

.separator {
  margin: 0 10px 0 10px;
}

#settingdiv {
  position: absolute;
  right: 0;
  top: 0;
}

#settingdiv > .el-button--text {
  color: #606266;
}

#settingdiv > .el-button {
  font-size: 20px;
}
</style>
