<template>
    <div class="browse">
      <top-search_phone @fatherMethod="fatherMethod"/>
      <div class="classSearch">
        <el-card>
          <div>
            <div>分类</div>
<!--            <template>-->
<!--              <el-row :gutter="20">-->
<!--                <el-col :span="4" v-for=" (item, index) in cLcBrowse" :key="index" :class="{active:index==clcTypeCheck}">-->
<!--                  <div class="clcTypeBox" @click="setTypeName(item.StrBookTypeName,index)" >-->
<!--                    <div class="strTypeName">{{item.StrBookTypeName}} {{item.Name}}</div>-->
<!--                    <div>({{item.TotalISBN}})</div>-->
<!--                  </div>-->
<!--                </el-col>-->
<!--              </el-row>-->
<!--            </template>-->
            <el-dropdown trigger="click" style="margin-top: 10px;margin-left: 3px">
                <span class="el-dropdown-link">
                  <span ref="fenleiName">分类</span><i class="el-icon-arrow-down el-icon--right"/><!--<i style="color: #61666d;font-size: 16px" class="el-icon-error el-icon&#45;&#45;left" />-->
                </span>
                <i ref="iconfenleidelete" style="margin-left: 5px;font-size: 16px;visibility: hidden" class="el-icon-circle-close" @click="iconFenLeiDeleteClick()"/>
              <el-dropdown-menu slot="dropdown">
                <div style="max-height: 300px;overflow: auto">
                  <el-dropdown-item
                    style="padding: 0"
                    v-for="(item, index) in cLcBrowse"
                    :class="{active:index===clcTypeCheck}"
                    :key="index">
                    <div class="clcTypeBox" @click="setTypeName(item.StrBookTypeName,item.Name,index)" style="padding:0 20px">
                      <div class="strTypeName">{{item.StrBookTypeName}} {{item.Name}} ({{item.TotalISBN}})</div>
                    </div>
                  </el-dropdown-item>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-card>
      </div>
<!--      <div v-else class="classSearch2">-->
<!--        <el-card>-->
<!--          <div>-->
<!--            <div>分类</div>-->
<!--            <template>-->
<!--              <el-row :gutter="20">-->
<!--                <el-col :span="4" v-for=" (item, index) in cLcBrowse" :key="index" :class="{active:index==clcTypeCheck}">-->
<!--                  <div class="clcTypeBox2" @click="setTypeName(item.StrBookTypeName,index)">-->
<!--                    <div class="strTypeName2">{{item.StrBookTypeName}} {{item.Name}}</div>-->
<!--                    <div>({{item.TotalISBN}})</div>-->
<!--                  </div>-->
<!--                </el-col>-->
<!--              </el-row>-->
<!--            </template>-->
<!--          </div>-->
<!--        </el-card>-->
<!--      </div>-->
      <div class="content">
        <div class="selectListBox">
          <el-card>
            <div class="selectedChoose">
              <div>已选</div>
              <el-tag
                :key="index"
                v-for="(tag, key, index) in selectedChoosesTag"
                closable
                :disable-transitions="false"
                @close="handleCloseTag(key)" class="tagBox" effect="plain">
                {{tag}}
              </el-tag>
            </div>
            <div class="menuChoose">

              <el-dropdown trigger="click" v-if="sidebarBranch">
                <span class="el-dropdown-link">
                  <span>分馆</span><i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <div style="max-height: 300px;overflow: auto">
                    <el-dropdown-item
                      style="padding: 0"
                      v-for="(option,i) in this.selectedChooses.libs"
                      :key="option.Name">
                      <div @click="selectedAndTagAdd(i,'libs')" style="padding: 0 20px;">{{option.Name}}({{option.Total}})</div>
                    </el-dropdown-item>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>

              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  <span>责任人</span><i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <div style="max-height: 300px;overflow: auto">
                    <el-dropdown-item
                      style="padding: 0"
                      v-for="(option,i) in this.selectedChooses.authors"
                      :key="option.Name">
                      <div @click="selectedAndTagAdd(i,'authors')" style="padding: 0 20px;">{{option.Name}}({{option.Total}})</div>
                    </el-dropdown-item>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
<!--                <el-menu :default-openeds='["publishers"]' @select="selectedAndTagAdd" :default-active="publishers"  ref="publishers" >-->
<!--                  <el-submenu index="publishers">-->
<!--                      <template slot="title">-->
<!--                        <span class="menuTitle">出版社</span>-->
<!--                      </template>-->
<!--                      <el-menu-item v-for="(item, i) in selectedChooses.publishers" :key="item.Name" :index="i.toString()">{{item.Name}}({{item.Total}})</el-menu-item>-->
<!--                    </el-submenu>-->
<!--                </el-menu>-->
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  <span>出版社</span><i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <div style="max-height: 300px;overflow: auto">
                    <el-dropdown-item
                      style="padding: 0"
                      v-for="(option,i) in this.selectedChooses.publishers"
                      :key="option.Name">
                      <div @click="selectedAndTagAdd(i,'publishers')" style="padding: 0 20px;">{{option.Name}}({{option.Total}})</div>
                    </el-dropdown-item>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
<!--                <el-menu :default-openeds='["publications"]' @select="selectedAndTagAdd" :default-active="publications" ref="publications" >-->
<!--                  <el-submenu index="publications" >-->
<!--                      <template slot="title">-->
<!--                        <span class="menuTitle">出版时间</span>-->
<!--                      </template>-->
<!--                    <div style="max-height: 100px;overflow: auto">-->
<!--                      <el-menu-item  v-for="(item, i) in selectedChooses.publications" :key="item.Name" :index="i.toString()">-->
<!--                        {{item.Name}}({{item.Total}})</el-menu-item>-->
<!--                    </div>-->
<!--                    </el-submenu>-->
<!--                </el-menu>-->
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  <span>出版时间</span><i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <div style="max-height: 300px;overflow: auto">
                    <el-dropdown-item
                      style="padding: 0"
                      v-for="(option,i) in this.selectedChooses.publications"
                      :key="option.Name">
                      <div @click="selectedAndTagAdd(i,'publications')" style="padding: 0 20px;">{{option.Name}}({{option.Total}})</div>
                    </el-dropdown-item>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-card>
        </div>
<!--        使用手机端的数据列表-->
        <div v-if="UseMobile" class="bookListsBox" v-loading="loading" element-loading-text="图书加载中...">
          <el-card>
            <div class="bookListTop">共{{totalBookNum}}条数据</div>
            <div class="bookListSelect">
              <div class="sortBox">
                <div class="sortSelect">
                  排序选项
                  <el-select v-model="searchForm.OrderBy" placeholder="默认排序" class="sortSelectChooseBox" @change="getBookList">
                    <el-option
                      v-for="option in sortSelects"
                      :key="option.index"
                      :label="option.optionName"
                      :value="option.index">
                    </el-option>
                  </el-select>
                </div>
                <div class="sortOrder">
                  排序方式
                  <el-select v-model="searchForm.Sort" placeholder="升序" class="sortOrderChooseBox" @change="getBookList">
                    <el-option
                      v-for="option in sortOrders"
                      :key="option.index"
                      :label="option.optionName"
                      :value="option.index">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="showOnLibBook" style="margin-top: 10px">
                <!-- `checked` 为 true 或 false -->
                <el-checkbox v-model="searchForm.OnLib" @change="getBookList">只显示在馆图书</el-checkbox>
              </div>
            </div>
            <hr class="hr"/>
            <div class="bookListBox" v-for="(book, index) in this.bookList" :key="index">
              <div class="bookBox" @click="getBookInfo(index)">
                <div class="bookImgBox" >
                  <img :src="book.StrCoverURL" alt="" style="width:95px;height: 100px;object-fit: contain;object-position: center;">
                </div>
                <div class="bookInfoBox">
                  <div class="bookTitle">{{book.StrPositiveTitle}}</div>
                  <div class="bookInfo">
                    <div class="bookInfoLeft">
                      <div>责任者：{{book.StrFirstResponsible}}</div>
                      <div>出版社：{{book.StrPublisher}}</div>
                      <div>ISBN：{{book.StrISBN}}</div>
                      <div>分类号：{{book.StrTypeName}}</div>
                      <div>出版时间：{{book.StrPublicationDate}}</div>
                      <div>关键词：{{book.StrKeywords}}</div>
                      <div>种次号：{{book.StrCallNumber}}</div>
                    </div>
                    <!--                    <div class="bookInfoRight">-->
                    <!--                      -->
                    <!--                    </div>-->
                  </div>
                  <div class="bookPreview">
                    <div class="libCollection">馆藏({{book.IntNumberCopies}})</div>
                  </div>
                </div>
              </div>
            </div>
            <div style="max-width: 100%;height: 35px;overflow: auto">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" small hide-on-single-page
                             :current-page="searchForm.Page" :page-size="searchForm.PerPage" layout="pager,jumper" :total="totalBookNum" :pager-count="5">
              </el-pagination>
            </div>
          </el-card>
        </div>

<!--        调用电脑端的数据列表  -->
        <div v-else class="bookListsBox2" v-loading="loading" element-loading-text="图书加载中...">
          <el-card>
            <div class="bookListTop2">共{{totalBookNum}}条数据</div>
            <div class="bookListSelect2">
              <div class="sortBox2">
                <div class="sortSelect2">
                  排序选项
                  <el-select v-model="searchForm.OrderBy" placeholder="默认排序" class="sortSelectChooseBox2" @change="getBookList">
                    <el-option
                      v-for="option in sortSelects"
                      :key="option.index"
                      :label="option.optionName"
                      :value="option.index">
                    </el-option>
                  </el-select>
                </div>
                <div class="sortOrder2">
                  排序方式
                  <el-select v-model="searchForm.Sort" placeholder="升序" class="sortOrderChooseBox2" @change="getBookList">
                    <el-option
                      v-for="option in sortOrders"
                      :key="option.index"
                      :label="option.optionName"
                      :value="option.index">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="showOnLibBook2">
                <!-- `checked` 为 true 或 false -->
                <el-checkbox v-model="searchForm.OnLib" @change="getBookList">只显示在馆图书</el-checkbox>
              </div>
            </div>
            <hr class="hr"/>
            <div class="bookListBox2" v-for="(book, index) in this.bookList" :key="index">
              <div class="bookBox2" @click="getBookInfo(index)">
                <div class="bookImgBox2" >
                  <img :src="book.StrCoverURL" alt="" id="myImage"  style="width:104px;height: 144px;object-fit: contain;object-position: center;margin-right: 20px">
                </div>
                <div class="bookInfoBox2">
                  <div class="bookTitle2">{{book.StrPositiveTitle}}</div>
                  <div class="bookInfo2">
                    <div class="bookInfoLeft2">
                      <div>责任者：{{book.StrFirstResponsible}}</div>
                      <div>出版社：{{book.StrPublisher}}</div>
                      <div>ISBN：{{book.StrISBN}}</div>
                      <div>分类号：{{book.StrTypeName}}</div>
                    </div>
                    <div class="bookInfoRight2">
                      <div>出版时间：{{book.StrPublicationDate}}</div>
                      <div>关键词：{{book.StrKeywords}}</div>
                      <div>种次号：{{book.StrCallNumber}}</div>
                    </div>
                  </div>
                  <div class="bookPreview2">
                    <div class="libCollection2">馆藏({{book.IntNumberCopies}})</div>
                  </div>
                </div>
              </div>
            </div>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           hide-on-single-page
                           :current-page="searchForm.Page" :page-sizes="[10, 20, 30, 50]"
                           :page-size="searchForm.PerPage" layout="sizes, prev, pager, next, jumper" :total="totalBookNum">
            </el-pagination>
          </el-card>
        </div>
      </div>
    </div>
</template>

<script>
import { getBooksByConditions, getBooksOthers } from '@/api'
import TopSearch_phone from '@/components/TopSearch/index_phone'
export default {
  components: { TopSearch_phone },
  data () {
    return {
      loading: true,
      UseMobile: true,
      notShowMenu: true,
      imgUrl: 'https://next.sanyue100.com/covers/',
      bookList: [],
      selectedChooses: {
        libs: [],
        authors: [],
        publishers: [],
        publications: []
      },
      sortSelects: [{
        index: 'DTCreatedDate',
        optionName: '默认排序'
      },
      {
        index: 'StrPositiveTitle',
        optionName: '书名'
      },
      {
        index: 'StrFirstResponsible',
        optionName: '责任人'
      },
      {
        index: 'StrPublisher',
        optionName: '出版社'
      },
      {
        index: 'StrPublicationDate',
        optionName: '出版日期'
      }],
      sortOrders: [{
        index: '-1',
        optionName: '降序'
      },
      {
        index: '1',
        optionName: '升序'
      }],
      totalBookNum: 0,
      selectedChoosesTag: {},
      searchForm: {
        Page: 1,
        PerPage: 10
      },
      cLcBrowse: [],
      clcTypeCheck: -1,
      libs: '',
      authors: '',
      publishers: '',
      publications: '',
      // 侧边栏是否需要分馆
      sidebarBranch: true
      // isCreate: false
    }
  },
  created () {
    this.UseMobile = document.body.clientWidth < 620
    // this.isCreate = true
    this.searchForm = JSON.parse(this.$route.query.res)
    // 书本不重复显示
    this.getBookList()
    this.getOtherInfo()
    if (this.searchForm.libs !== '' && this.searchForm.libs !== undefined) {
      this.$set(this.selectedChoosesTag, 'libs', this.searchForm.libs)
    }
    if (this.searchForm.authors !== '' && this.searchForm.authors !== undefined) {
      this.$set(this.selectedChoosesTag, 'authors', this.searchForm.authors)
    }
    if (this.searchForm.publishers !== '' && this.searchForm.publishers !== undefined) {
      this.$set(this.selectedChoosesTag, 'publishers', this.searchForm.publishers)
    }
    if (this.searchForm.publications !== '' && this.searchForm.publications !== undefined) {
      this.$set(this.selectedChoosesTag, 'publications', this.searchForm.publications)
    }
    if (localStorage.getItem('library') === '' || localStorage.getItem('library') === null) {
      this.sidebarBranch = false
    }
  },
  methods: {
    // 搜索框点击搜索按钮时调用方法
    fatherMethod () {
      this.searchForm = JSON.parse(this.$route.query.res)
      // 书本不重复显示
      this.getBookList()
      this.getOtherInfo()
      if (this.searchForm.libs !== '' && this.searchForm.libs !== undefined) {
        this.$set(this.selectedChoosesTag, 'libs', this.searchForm.libs)
      }
      if (this.searchForm.authors !== '' && this.searchForm.authors !== undefined) {
        this.$set(this.selectedChoosesTag, 'authors', this.searchForm.authors)
      }
      if (this.searchForm.publishers !== '' && this.searchForm.publishers !== undefined) {
        this.$set(this.selectedChoosesTag, 'publishers', this.searchForm.publishers)
      }
      if (this.searchForm.publications !== '' && this.searchForm.publications !== undefined) {
        this.$set(this.selectedChoosesTag, 'publications', this.searchForm.publications)
      }
    },
    // 点击分类删除图标
    iconFenLeiDeleteClick () {
      this.$refs.fenleiName.innerText = '分类'
      this.searchForm.CLCCheck = ''
      this.clcTypeCheck = -1
      this.$refs.iconfenleidelete.style.visibility = 'hidden'
      this.getBookList()
      this.getOtherInfo()
    },
    async getBookList () {
      this.loading = true
      if (this.searchForm.Page !== undefined) { this.searchForm.Page = 1 }
      const result = await getBooksByConditions(this.searchForm)
      if (result.ok) {
        this.bookList = result.msg
        this.totalBookNum = result.total
        for (var i in this.bookList) {
          this.getImg(this.bookList[i])
        }
      } else {
        return this.$message(result.msg)
      }
      this.loading = false
    },
    async getOtherInfo () {
      const result = await getBooksOthers(this.searchForm)
      if (result.ok) {
        this.cLcBrowse = result.CLC
        this.selectedChooses.libs = result.Libs
        this.selectedChooses.authors = result.Authors
        this.selectedChooses.publishers = result.Publishers
        this.selectedChooses.publications = result.Publications
      } else {
        return this.$message(result.msg)
      }
    },
    setTypeName (typeName, name, index) {
      this.$refs.fenleiName.innerText = typeName + ' ' + name
      this.clcTypeCheck = index
      this.searchForm.CLCCheck = typeName
      this.$refs.iconfenleidelete.style.visibility = 'visible'
      this.getBookList()
      this.getOtherInfo()
    },
    handleCloseTag (key) {
      this.$delete(this.selectedChoosesTag, key)
      this.libs = '1'
      // this.$refs[key].activeIndex = null  ???
      this.searchForm[key] = ''
      this.getBookList()
      this.getOtherInfo()
    },
    selectedAndTagAdd (key, keyPath) {
      // var className = keyPath[0]
      var className = keyPath
      console.log(className, key)
      this.$set(this.selectedChoosesTag, className, this.selectedChooses[className][key].Name)
      this.searchForm[className] = this.selectedChooses[className][key].Name
      this.getBookList()
      this.getOtherInfo()
    },
    // 每页显示条数更改
    handleSizeChange (newSize) {
      this.searchForm.PerPage = newSize
      this.getBookList()
    },
    // 监听页码值改变的事件
    handleCurrentChange (newPage) {
      this.searchForm.Page = newPage
      this.getBookListPageChange()
    },
    async getBookListPageChange () {
      this.loading = true
      const result = await getBooksByConditions(this.searchForm)
      if (result.ok) {
        this.bookList = result.msg
        this.totalBookNum = result.total
        for (var i in this.bookList) {
          this.getImg(this.bookList[i])
        }
      } else {
        return this.$message(result.msg)
      }
      this.loading = false
    },

    getBookInfo (index) {
      var book = this.bookList[index]
      this.$router.push({
        path: '/bookInfo',
        query: { res: JSON.stringify(this.searchForm), book: JSON.stringify(book), router: 'bookList' }
      })
    }
  }
}
</script>

<style lang="less" scoped>
//下拉菜单
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.el-icon-arrow-down {
  font-size: 12px;
}

.menuChoose {
  display: flex;
  justify-content: space-around;
  padding-bottom: 20px;
  padding-left: 15px;
}
.searchTop {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.searchItem{
  margin-right: 20px;
}
.searchSelect{
  width: 160px;
}
.searchInput{
  width: 400px;
}
.classSearch {
  width: 100%;
  margin: 20px auto 0px auto;
}
.content{
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
}
.selectListBox{
  width: 100%;
  /deep/.el-card__body {
    padding: 0;
  }
}
.bookListsBox{
  width: 100%;
  margin-top: 20px;
}
.selectLib {
  width: 240px;
  margin: 20px 0 auto;
}
.bookListSelect{
  margin-top: 20px;
  //display: flex;
  align-items: center;
  justify-content: space-between;
}
.sortBox {
  display: flex;
}
.sortSelect{
  margin-right: 20px;
}
.sortSelectChooseBox{
  margin-left: 10px;
  width: 140px;
}
.sortOrderChooseBox{
  margin-left: 10px;
  width: 100px;
}
.hr{
  background-color: #e5e5e5;
  height:1px;
  border:none;
  margin: 30px 0 0 0;
}

.el-menu {
  border: none;
}
.menuTitle {
  font-size: 16px;
  font-weight: 700;
}
.el-submenu__title {
  height: 40px !important;
}
.el-submenu {
  background-color: #f2f2f2;
}
.selectedChoose {
  margin: 20px 0 10px 20px;
}
.tagBox {
  margin: 5px;
}
.bookListBox {
  //margin-bottom: 14px;
}
.bookBox {
  padding-top: 10px;
  padding-bottom: 10px;
  //height: 170px;
  display: flex;
  border-bottom: #e5e5e5 1px solid;
  cursor: pointer;
  .bookImgBox {
    margin-top: 20px;
    margin-right: 10px;
    height: 140px;
  }
  .bookTitle{
    font-weight: 700;
    margin-bottom: 10px;
    line-height: 1.25;
  }
}
.bookPreview {
  display: flex;
}
.bookInfo {
  color: #61666d;
  font-size: 12px;
  margin-bottom: 10px;
  display: flex;
  div{
    margin-bottom: 2px;
  }
}
.libCollection {
  color: #409eff;
  font-size: 14px;
}
.bookListBox {
  margin-bottom: 14px;
}
.el-row {
  margin-top: 6px;
}
.el-col {
  width: 45%;
  .clcTypeBox {
    padding:0 14px;
    width: 100%;
    height: 34px;
    line-height: 34px;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    border-radius: 6px;
    align-items: center;
    cursor: pointer;
    .strTypeName {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.clcTypeBox:hover{
  background-color: #f2f3f5;
}
.active{
  .clcTypeBox {
    background-color: #f2f3f5;
  }
}

//电脑端的数据列表格式
.bookListsBox2{
  margin-top: 20px;
  min-width: 600px;
}
.bookListSelect2{
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sortBox2 {
  display: flex;
}
.sortSelect2{
  margin-right: 20px;
}
.sortSelectChooseBox2{
  margin-left: 10px;
  width: 140px;
}
.sortOrderChooseBox2{
  margin-left: 10px;
  width: 100px;
}
.bookBox2 {
  padding: 30px;
  height: 170px;
  display: flex;
  border-bottom: #e5e5e5 1px solid;
  cursor: pointer;
  .bookImgBox2 {
    flex: 1;
    height: 140px;
  }
  .bookInfoBox2 {
    flex: 4;
  }
  .bookTitle2{
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 1.25;
  }
}
.bookPreview2 {
  display: flex;
}
.bookInfo2 {
  color: #61666d;
  font-size: 14px;
  margin-bottom: 18px;
  display: flex;
  div{
    margin-bottom: 2px;
  }
  .bookInfoRight2{
    flex: 5;
  }
  .bookInfoLeft2 {
    flex: 4;
  }
}
.libCollection2 {
  color: #409eff;
  font-size: 16px;
}
.bookListBox2 {
  margin-bottom: 14px;
}
//.classSearch {
//  min-width: 600px;
//  margin: 20px auto 0px auto;
//}
//.el-row {
//  margin-top: 6px;
//}
//.el-col {
//  display: flex;
//  .clcTypeBox2 {
//    padding:0 14px;
//    width: 100%;
//    height: 34px;
//    line-height: 34px;
//    display: flex;
//    overflow: hidden;
//    text-overflow: ellipsis;
//    white-space: nowrap;
//    font-size: 14px;
//    border-radius: 6px;
//    align-items: center;
//    cursor: pointer;
//    .strTypeName2 {
//      overflow: hidden;
//      text-overflow: ellipsis;
//      white-space: nowrap;
//    }
//  }
//}
</style>
