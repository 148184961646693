<template>
    <div class="topSearch">
        <div class="logoImg">
          <img src="../../assets/img/logoImg.png" alt="" height="50px">
        </div>
<!--   书名 下拉选择   -->
        <div class="searchSelect searchItem">
          <el-select v-model="searchForm.KeyName" placeholder="请选择">
            <el-option
              v-for="option in optionSelects"
              :key="option.index"
              :label="option.optionName"
              :value="option.index">
            </el-option>
          </el-select>
        </div>
        <div class="searchInput searchItem">
          <el-input v-model="searchForm.StrKeywords" placeholder="请输入关键词" @keydown.enter.native="getSearchBookListToBookList"></el-input>
        </div>
        <div class="searchButton">
          <el-button type="primary" @click="getSearchBookListToBookList" >检索</el-button>
        </div>
      </div>
</template>
<script>

export default {
  name: 'topSearch',
  data () {
    return {
      optionSelects: [
        {
          index: 'all',
          optionName: '任意词'
        },
        {
          index: 'StrPositiveTitle',
          optionName: '书名'
        }, {
          index: 'StrFirstResponsible',
          optionName: '作者'
        }, {
          index: 'StrISBN',
          optionName: 'ISBN'
        }, {
          index: 'StrPublisher',
          optionName: '出版社'
        }],
      searchForm: {
        KeyName: '',
        StrKeywords: '',
        Sort: '-1',
        OrderBy: 'DTCreatedDate',
        OnLib: false,
        Distinct: true,
        Page: 1,
        PerPage: 10,
        StrOrganizationIDs: []
      }
    }
  },
  mounted () {
    this.searchForm.StrOrganizationIDs[0] = localStorage.getItem('library') == null ? '' : localStorage.getItem('library')
    this.searchForm.StrKeywords = sessionStorage.getItem('searchContent') == null ? '' : sessionStorage.getItem('searchContent')
    const keyname = sessionStorage.getItem('keyName')
    if (keyname !== null && keyname !== '') {
      this.searchForm.KeyName = keyname
    } else {
      this.searchForm.KeyName = 'all'
    }
    var index = window.location.href
    var newbook = 'newBook'
    var hotbook = 'hotBook'
    if (!index.includes(newbook) && !index.includes(hotbook)) {
      this.getSearchBookListToBookList()
    }
  },
  methods: {
    childMethod () {
      this.$emit('fatherMethod')
    },
    getSearchBookListToBookList () {
      // 去除两端的空格，防止出现因为空格导致搜索不到的问题
      this.searchForm.StrKeywords = this.searchForm.StrKeywords.trim()
      if (this.searchForm.StrKeywords === '') {
        alert('请输入搜索词')
        return
      }
      sessionStorage.setItem('keyName', this.searchForm.KeyName)
      sessionStorage.setItem('searchContent', this.searchForm.StrKeywords)
      console.log(this.searchForm)
      this.$router.push({
        path: '/bookList',
        query: { res: JSON.stringify(this.searchForm) }
      })
      this.childMethod()
    }
  }
}
</script>
<style lang="less" scoped>
.topSearch {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.searchItem{
  margin-right: 20px;
}
.searchSelect{
  width: 160px;
}
.searchInput{
  width: 400px;
}
</style>
