<template>
    <div class="detailInfo">
      <topSearch />
      <div class="content">
        <el-card>
          <div class="selectBox">
<!--            <div class="selectTime">-->
<!--              编目日期-->
<!--              <el-date-picker-->
<!--                v-model="searchForm.OrderByTime"-->
<!--                type="daterange"-->
<!--                range-separator="-"-->
<!--                start-placeholder="开始日期"-->
<!--                end-placeholder="结束日期" class="timeSelectBox" @change="getNewBooks">-->
<!--              </el-date-picker>-->
<!--            </div>-->
<!--            <div class="selectLib">-->
<!--              分馆-->
<!--              <el-select v-model="searchForm.StrOrganizationID" placeholder="请选择" class="selectItemBox" @change="getNewBooks" clearable>-->
<!--                <el-option-->
<!--                  v-for="option in selectLibs"-->
<!--                  :key="option.StrID"-->
<!--                  :label="option.StrName"-->
<!--                  :value="option.StrID">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </div>-->
            <div class="selectClass">
              分类
              <el-select v-model="searchForm.CLCCheck" placeholder="请选择" class="selectItemBox" @change="getNewBooks" clearable>
                <el-option
                  v-for="option in selectClasses"
                  :key="option.StrBookTypeName"
                  :label="option.StrBookTypeName + ' ' + option.Name"
                  :value="option.StrBookTypeName">
                </el-option>
              </el-select>
            </div>
          </div>
        </el-card>
        <el-card style="margin-top:10px;" v-loading="loading" element-loading-text="图书加载中...">
          <div class="showOnLibBook">
            <el-checkbox v-model="searchForm.OnLib" @change="getNewBooks">只显示在馆图书</el-checkbox>
          </div>
          <hr class="hr"/>
          <div class="bookListBox" v-for="(book, index) in this.bookList" :key="index">
              <div class="bookBox" @click="getBookInfo(index)">
                <div class="bookImgBox">
                  <img :src="book.StrCoverURL" alt="" style="width:104px;height: 144px;object-fit: contain;object-position: center;">
                </div>
                <div class="bookInfoBox">
                  <div class="bookTitle">{{book.StrPositiveTitle}}</div>
                  <div class="bookInfo">
                    <div class="bookInfoLeft">
                      <div>责任者：{{book.StrFirstResponsible}}</div>
                      <div>出版社：{{book.StrPublisher}}</div>
                      <div>ISBN：{{book.StrISBN}}</div>
                      <div>分类号：{{book.StrTypeName}}</div>
                    </div>
                    <div class="bookInfoRight">
                      <div>出版时间：{{book.StrPublicationDate}}</div>
                      <div>关键词：{{book.StrKeywords}}</div>
                      <div>种次号：{{book.StrCallNumber}}</div>
                    </div>
                  </div>
                  <div class="bookPreview">
                    <div class="libCollection">馆藏({{book.IntNumberCopies}})</div>
                  </div>
                </div>
              </div>
            </div>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" hide-on-single-page
              :current-page="searchForm.Page" :page-sizes="[10, 20, 30, 50]" :page-size="searchForm.PerPage"
                           layout="sizes, prev, pager, next, jumper" :total="totalBookNum">
            </el-pagination>
        </el-card>
      </div>
    </div>
</template>

<script>
import { getNewBooks, getAllLibs, getClcType } from '@/api'
export default {
  name: 'detailInfo',
  data () {
    return {
      loading: true,
      imgUrl: 'https://next.sanyue100.com/covers/',
      selectLibs: [{
        index: '',
        optionName: ''
      }],
      selectClasses: [{
        index: '',
        optionName: ''
      }],
      searchForm: {
        OnLib: false,
        Page: 1,
        PerPage: 10,
        CLCCheck: ''
      },
      bookList: [],
      totalBookNum: 0
    }
  },
  mounted () {
    this.searchForm.StrOrganizationID = localStorage.getItem('library') == null ? '' : localStorage.getItem('library')
    this.getNewBooks()
    // this.getAllLibs()
    this.getClc()
  },
  methods: {
    async getAllLibs () {
      const result = await getAllLibs()
      if (result.ok) {
        this.selectLibs = result.msg
        // console.log(this.libraryAddresses)
      } else {
        return this.$message(result.msg)
      }
    },
    async getClc () {
      const result = await getClcType()
      if (result.ok) {
        this.selectClasses = result.msg
        // console.log(this.libraryAddresses)
      } else {
        return this.$message(result.msg)
      }
    },
    async getNewBooks () {
      this.loading = true
      this.searchForm.Page = 1
      const result = await getNewBooks(this.searchForm)
      if (result.ok) {
        this.bookList = result.msg
        // this.selectClasses = Object.assign({}, result.ClC)
        this.totalBookNum = result.total
        for (var i in this.bookList) {
          this.getImg(this.bookList[i])
        }
        // console.log(this.libraryAddresses)
      } else {
        return this.$message(result.msg)
      }
      this.loading = false
    },
    // 每页显示条数更改
    handleSizeChange (newSize) {
      this.searchForm.PerPage = newSize
      this.getNewBooksPageChange()
    },
    // 监听页码值改变的事件
    handleCurrentChange (newPage) {
      this.searchForm.Page = newPage
      this.getNewBooksPageChange()
    },
    async getNewBooksPageChange () {
      this.loading = true
      const result = await getNewBooks(this.searchForm)
      if (result.ok) {
        this.bookList = result.msg
        // this.selectClasses = Object.assign({}, result.ClC)
        this.totalBookNum = result.total
        for (var i in this.bookList) {
          this.getImg(this.bookList[i])
        }
        // console.log(this.libraryAddresses)
      } else {
        return this.$message(result.msg)
      }
      this.loading = false
    },
    getBookInfo (index) {
      var book = this.bookList[index]
      this.$router.push({
        path: '/bookInfo',
        query: { res: JSON.stringify(this.searchForm), book: JSON.stringify(book), router: 'newBook' }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content{
  width: 1092px;
  margin: 0 auto;
  margin-top: 20px;
}
.selectBox{
  display: flex;
  align-items: center;
}
.selectItemBox {
  margin-left: 10px;
  width: 200px;
}
.timeSelectBox{
  margin-left:10px;
  width:280px !important;
}
.selectLib, .selectClass{
  margin-left: 20px;
}
.hr{
  background-color: #e5e5e5;
  height:1px;
  border:none;
  margin: 16px 0 30px 0;
}
.bookListBox {
  margin-bottom: 14px;
}
.bookBox {
  padding: 30px;
  height: 170px;
  display: flex;
  border-bottom: #e5e5e5 1px solid;
  cursor: pointer;
  .bookImgBox {
    flex: 1;
    height: 140px;
  }
  .bookInfoBox {
    flex: 4;
  }
  .bookTitle{
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 1.25;
  }
}
.bookPreview {
  display: flex;
}
.bookInfo {
  color: #61666d;
  font-size: 14px;
  margin-bottom: 18px;
  display: flex;
  div{
    margin-bottom: 2px;
  }
  .bookInfoRight{
    flex: 5;
  }
  .bookInfoLeft {
    flex: 4;
  }
}
.libCollection {
  color: #409eff;
  font-size: 14px;
}
</style>
