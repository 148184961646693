<template>
    <div class="browse">
      <topSearch />
      <div class="content">
        <div class="selectListBox">
          <el-card class="selectBox">
            <div>中图分类法</div>
            <!-- <div class="selectType">
              <el-select v-model="selectTypeDefaultValue" placeholder="全部文献类型">
                <el-option
                  v-for="option in selectTypes"
                  :key="option.index"
                  :label="option.optionName"
                  :value="option.index">
                </el-option>
              </el-select>
            </div> -->
            <div class="selectLib">
              <el-select v-model="searchForm.StrOrganizationIDs[0]" placeholder="请选择" @change="getCLCChange">
                <el-option
                  v-for="address in libraryAddresses"
                  :key="address.StrID"
                  :label="address.StrName"
                  :value="address.StrID">
                </el-option>
              </el-select>
            </div>
            <div class="selectClass"></div>
          </el-card>
        </div>
        <div class="bookListsBox">
          <el-card>
            <div class="bookListTop">共{{totalBookNum}}本图书</div>
            <div class="bookListSelect">
              <div class="sortBox">
                <div class="sortSelect">
                  排序选项
                  <el-select v-model="searchForm.OrderBy" placeholder="默认排序" class="sortSelectChooseBox">
                    <el-option
                      v-for="option in sortSelects"
                      :key="option.index"
                      :label="option.optionName"
                      :value="option.index">
                    </el-option>
                  </el-select>
                </div>
                <div class="sortOrder">
                  排序方式
                  <el-select v-model="searchForm.Sort" placeholder="升序" class="sortOrderChooseBox">
                    <el-option
                      v-for="option in sortOrders"
                      :key="option.index"
                      :label="option.optionName"
                      :value="option.index">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="showOnLibBook">
                <!-- `checked` 为 true 或 false -->
                <el-checkbox v-model="searchForm.OnLib">只显示在馆图书</el-checkbox>
              </div>
            </div>
            <hr class="hr"/>
            <div class="bookListBox"></div>
          </el-card>
        </div>
      </div>
    </div>
</template>

<script>
import { getBooksOthers, getAllLibs } from '@/api'
export default {
  data () {
    return {
      libraryAddresses: [{}],
      sortSelects: [{
        index: 'DTCreatedDate',
        optionName: '默认排序'
      },
      {
        index: 'StrPositiveTitle',
        optionName: '书名'
      },
      {
        index: 'StrFirstResponsible',
        optionName: '责任人'
      },
      {
        index: 'StrPublisher',
        optionName: '出版社'
      },
      {
        index: 'StrPublicationDate',
        optionName: '出版日期'
      }],
      sortOrders: [{
        index: '-1',
        optionName: '降序'
      },
      {
        index: '1',
        optionName: '升序'
      }],
      totalBookNum: 0,
      searchForm: {
        Sort: '-1',
        OrderBy: 'DTCreatedDate',
        OnLib: false,
        Distinct: true,
        Page: 1,
        PerPage: 10,
        StrOrganizationIDs: []
      },
      CLCList: []
    }
  },
  created () {
    // this.strId = this.$route.query.item
    // this.strSchoolId = this.$route.query.strId
    this.searchLibraryAddresses()
    this.getBooksOthers()
  },
  methods: {
    async searchLibraryAddresses () {
      const result = await getAllLibs()
      if (result.ok) {
        this.libraryAddresses = result.msg
        // console.log(this.libraryAddresses)
      } else {
        return this.$message(result.msg)
      }
    },
    async getBooksOthers () {
      const result = await getBooksOthers(this.searchForm)
      if (result.ok) {
        console.log(result.CLC)
        this.CLCList = result.CLC
      } else {
        return this.$message(result.msg)
      }
    },
    getCLCChange () {
      this.getBooksOthers()
    }
  }
}
</script>

<style lang="less" scoped>
.searchTop {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.searchItem{
  margin-right: 20px;
}
.searchSelect{
  width: 160px;
}
.searchInput{
  width: 400px;
}
.content{
  width: 1092px;
  display: flex;
  margin: 0 auto;
  margin-top: 20px;
}
.selectListBox{
  margin-right: 10px;
  flex: 1;
}
.bookListsBox{
  margin-left: 10px;
  flex: 3;
}
.selectLib , .selectType {
  width: 240px;
  margin: 20px 0 auto;
}
.bookListSelect{
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sortBox {
  display: flex;
}
.sortSelect{
  margin-right: 20px;
}
.sortSelectChooseBox{
  margin-left: 10px;
  width: 140px;
}
.sortOrderChooseBox{
  margin-left: 10px;
  width: 100px;
}
.hr{
  background-color: #e5e5e5;
  height:1px;
  border:none;
  margin: 40px 0 30px 0;
}
</style>
