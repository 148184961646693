import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/Home.vue'
import bookList from '../views/BookList.vue'
import bookInfo from '../views/BookInfo.vue'
import index from '../views/Index.vue'
import browse from '../views/Browse.vue'
import hotBook from '../views/HotBook.vue'
import newBook from '../views/NewBook.vue'
import index_phone from '@/views/Index_phone'
import home_phone from '@/views/Home_phone'
import newBook_phone from '@/views/NewBook_phone'
import hotBook_phone from '@/views/HotBook_phone'
import bookList_phone from '@/views/BookList_phone'
import bookInfo_phone from '@/views/BookInfo_phone'

Vue.use(VueRouter)

// 默认路由
export const routes = [

]
// pc端路由
export const routes_pc = [
  {
    path: '/',
    component: home,
    redirect: '/index',
    children: [
      {
        path: '/index',
        component: index
      }, {
        path: '/bookList',
        component: bookList
      }, {
        path: '/bookInfo',
        component: bookInfo
      }, {
        path: '/browse',
        component: browse
      }, {
        path: '/hotBook',
        component: hotBook
      }, {
        path: '/newBook',
        component: newBook
      }
    ]
  }
]
// 手机端路由
export const routes_phone = [
  {
    path: '/',
    component: home_phone,
    redirect: '/index',
    children: [
      {
        path: '/index',
        component: index_phone
      }, {
        path: '/bookList',
        component: bookList_phone
      }, {
        path: '/bookInfo',
        component: bookInfo_phone
      }, {
        path: '/browse',
        component: browse
      }, {
        path: '/hotBook',
        component: hotBook_phone
      }, {
        path: '/newBook',
        component: newBook_phone
      }
    ]
  }
]
// 判断设备是否为移动端的方法
export const isMobile = () => {
  const moblie = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
    navigator.userAgent
  )
  const bili = document.body.clientWidth
  if (bili < 1092 && moblie) {
    return true
  }
  return false
}

const createRouter = () =>
  new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    mode: 'hash',
    routes: routes
  })

// app内嵌页面报错，防止路由冗余
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router

// const router = new VueRouter({
//   routes
// })
// export default router
